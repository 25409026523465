import { Injectable } from '@angular/core';//injectable represents it is a service
import { HttpClient } from '@angular/common/http';//to do http calls we have to import httpclient from angularhttp
import { environment } from '../../environments/environment';//to get base url to connect the server
import { of, Observable } from 'rxjs';
import { delay } from 'rxjs/internal/operators';

//service decotator
@Injectable()
export class masterService {
    sessionUser:any;
  //base host in api
  private host = environment.API_END_POINT;
  //url
  private url: string = '';

  constructor(private http: HttpClient) { 

  }
  getDemographyData(): Observable<any>{
    var data = {
      "method":"citizen_all_city"
      } ;
    this.url = this.host;
    return this.http.post(this.url,data);
  }
}


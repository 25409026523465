import { Component, OnInit } from '@angular/core';
import { shopdetailscontentService } from './shopdetailscontent.service';
import { ActivatedRoute } from '@angular/router'
import { GlobalService } from '../global.service';
import { MatDialog } from '@angular/material';
import { LoginComponent } from '../login/login.component';
@Component({
  selector: 'app-shopdetailscontent',
  templateUrl: './shopdetailscontent.component.html',
  styleUrls: ['./shopdetailscontent.component.css']
})
export class ShopdetailscontentComponent implements OnInit {
  myShopDetails = [];
  myShopPost: any;
  schedule: any;
  following = false;
  userId

  constructor(public dialog: MatDialog, private shopdetailscontentService: shopdetailscontentService,
    private ActivatedRoute: ActivatedRoute, private objGlobal: GlobalService) { }

  ngOnInit() {
    this.userId = localStorage.getItem("tokenId");

    this.ActivatedRoute.params.subscribe(params => {
      this.myShopDetails = []
      this.myShopPost = []
      this.schedule = []
      this.shopdetailscontentService.getShopDetailsData(this.objGlobal.shopId).subscribe((data: any) => {
        if (data.data[0].is_following) {
          this.following = true;
        }
        this.myShopDetails.push(data.data[0]);
        this.myShopPost = data.data[0].posts;
        this.schedule = data.data[0].working_time;
      })
    });

  }

  follow() {
    if (!this.userId) {
      this.dialog.open(LoginComponent, { width: "400px" });
    } else {
      this.following = !this.following;
      this.shopdetailscontentService.follow(this.objGlobal.shopId, this.following).subscribe((response: any) => { })
    }
  }
}

import { Injectable } from '@angular/core';//injectable represents it is a service
import { HttpClient } from '@angular/common/http';//to do http calls we have to import httpclient from angularhttp
import { environment } from '../../../environments/environment';//to get base url to connect the server
import { GlobalService } from '../global.service';

//service decotator
@Injectable()
export class mallofferslistpageService {
    sessionUser:any;
  //base host in api
  private host = environment.API_END_POINT;
  //url
  private url: string = '';

  constructor(private http: HttpClient,private objGlobal: GlobalService) { 

  }
  getAllMalllData(startindx){
    var data = {
      "method":"citizen_all_malls",
      "city_id":this.objGlobal.cityid,
      "start_index": startindx,
      "limit": "6"
      } ;
    this.url = this.host;
    return this.http.post(this.url,data);
  }
}


import { Component, OnInit } from '@angular/core';
import { addlistingServiceH } from './addlistingH.service';
import { GlobalService } from '../global.service';
import Swal from 'sweetalert2'
declare var $: any;
@Component({
  selector: 'app-addlisting',
  templateUrl: './addlisting.component.html',
  styleUrls: ['./addlisting.component.css']
})
export class AddlistingComponent implements OnInit {
  category :any;
  shopname :any;
  cntname :any;
  mobile :any;
  email :any;
  btnstatus:boolean=true
  btnText="Submit"
  arrcats: any;
  constructor(public addlistingService: addlistingServiceH,private objGlobal: GlobalService) { }

  ngOnInit() {
    var obj=this
    this.arrcats=obj.objGlobal.categorylist
  }
  register() {
    this.btnstatus=false
    this.btnText="Please wait.."
    var objthis=this;
    let obj={
      "method":"citizen_register_as_shop",
      "category_id":this.category,
      "shop_name":this.shopname,
      "contact_person":this.cntname,
      "mobile":this.mobile,
      "email":this.email
    }
    this.addlistingService.addListiningRegister(obj).subscribe((data:any)=>{
    
      if(data.responseCode==200)
      {
       Swal.fire({
         type: 'success',
         text:  data.message
       }).then(function(result){
        
       })
      }
     else{
       Swal.fire({
         type: 'error',
         text:  data.message
       }).then(function(result){
         
       })
     }
     objthis.btnstatus=true
     objthis.btnText="Submit"
    this.category=""
    this.shopname=""
    this.cntname=""
    this.mobile=""
    this.email=""
    
    })
   

  }
}

import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { advertising } from './advertising.service';
import { EventEmitterService } from '../event-emitter.service';
import { GlobalService } from '../global.service';
import { Subscription } from 'rxjs';
import $ from 'jquery';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { LoginComponent } from '../login/login.component';

@Component({
  selector: 'app-advertising',
  templateUrl: './advertising.component.html',
  styleUrls: ['./advertising.component.css']
})
export class AdvertisingComponent implements OnInit, OnDestroy {
  userId
  arrAdv = new Array();
  startindex: any = 0
  constructor(public dialog: MatDialog, public router: Router, private advertising: advertising, private eventEmitterService: EventEmitterService) { }
  subsidebanner: Subscription;
  scrollStatus: any = "Y"
  loadingstatus: boolean = false
  loadingfakestatus: boolean = true;
  pageName: any = '';
  innerWidth: any = 0;
  hideAds: any = true;
  ngOnDestroy() {
    this.subsidebanner.unsubscribe();
  }
  ngOnInit() {
    this.userId = localStorage.getItem("tokenId");

    var obj = this;
    this.subsidebanner = this.eventEmitterService.invokesidebannercomponentFunction.subscribe(() => {
      this.startindex = 0;
      this.advertising.getadvertisingData(this.startindex).subscribe((data: any) => {
        obj.arrAdv = [];
        // this.arrAdv=data.data;

        if (data.data.length > 0) {
          data.data.forEach(element => {
            this.arrAdv.push({ packType: element.pack_type, image: element.web_image, mapLink: element.map_link, bookmarked: element.has_bookmarked, id: element.id });
          });
          this.loadingstatus = true
          this.loadingfakestatus = false
        }
        else {
          this.scrollStatus = "N"
        }

      })
      //this.subsidebanner.unsubscribe();
    });
    this.advertising.getadvertisingData(this.startindex).subscribe((data: any) => {
      //this.arrAdv=data.data;
      if (data.data.length > 0) {
        data.data.forEach(element => {
          this.arrAdv.push({ packType: element.pack_type, image: element.web_image, mapLink: element.map_link, bookmarked: element.has_bookmarked, id: element.id });
        });
        this.loadingstatus = true
        this.loadingfakestatus = false
      }
      else {
        this.scrollStatus = "N"
      }
    })
    $(function () {
      if ($('.sideBanner').length) {
        var topVal = $('.sideBanner').offset().top - 120;
        var bnrWidth = $('.sideBanner').width();
        // console.log('---------------------- '+topVal);
        $(window).scroll(function () {
          // console.log(topVal +' --- '+ $(this).scrollTop());
          if (topVal <= $(this).scrollTop()) {
            // console.log(1);
            $('.sideBanner').addClass('fixed').width(bnrWidth);
          }
          else {
            // console.log(0);
            $('.sideBanner').removeClass('fixed').width('auto');
          }
        });
      }
    });
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 480) {
      this.hideAds = true;
    }
    else {
      this.hideAds = false;
    }
    this.pageName = this.router.url;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 480) {
      this.hideAds = true;
    }
    else {
      this.hideAds = false;
    }
  }
  openNewTab(link) {
    window.open(link, '_blank');
  }
  onScroll() {
    //   this.loadingstatus=true;
    //   var obj=this;
    //   this.startindex=(this.startindex)+2; 
    //   this.advertising.getadvertisingData(this.startindex).subscribe((data:any)=>{
    //     //console.log(data)
    //     if(data.data.length>0){
    //       data.data.forEach(element => {
    //         var serie = new Array(element.web_image, element.map_link,element.shop_name);
    //          this.arrAdv.push(serie);

    //       });
    //     }
    //     else{
    //       this.scrollStatus="N"
    //     }
    //     this.loadingstatus=false;
    // })
  }

  bookmarkImage(item) {
    if (!this.userId) {
      this.dialog.open(LoginComponent, { width: "400px" });
    } else {
      item.bookmarked = !item.bookmarked;
      this.advertising.bookmarkOffer(item.id, item.bookmarked).subscribe();
    }
  }
}

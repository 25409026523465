import { Component, OnInit, OnDestroy } from "@angular/core";
import { PopularcatService } from "../popularcategories/popularcategories.service";
import { GlobalService } from "../global.service";
import { EventEmitterService } from "../event-emitter.service";
import { Subscription, Observable } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";
@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent implements OnInit, OnDestroy {
  arrcats: any = [];
  arrcats1: any = [];
  arrcats2: any = [];
  sub: Subscription;
  currentYear: number;
  constructor(
    private popularcatService: PopularcatService,
    private objGlobal: GlobalService,
    private eventEmitterService: EventEmitterService,
    private ActivatedRoute: ActivatedRoute,
    private router: Router
  ) {}
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  ngOnInit() {
    this.currentYear = new Date().getFullYear();
    var obj = this;
    this.arrcats = [];
    this.arrcats1 = [];
    this.arrcats2 = [];
    this.sub =
      this.eventEmitterService.invokeCategoryfootercomponentFunction.subscribe(
        (data) => {
          for (let i = 0; i < data.length; i++) {
            if (i < 5) {
              obj.arrcats.push(data[i]);
            } else if (i < 10 && i >= 5) {
              obj.arrcats1.push(data[i]);
            } else if (i < 15 && i >= 10) {
              obj.arrcats2.push(data[i]);
            }
          }
        }
      );
    //   this.popularcatService.getpopularcategory("23").subscribe((data:any)=>{
    //     for(var i=0;i<=data.data.length;i++){

    //       if(i<5)
    //       obj.arrcats.push(data.data[i])
    //       else if(i<10 && i>=5)
    //       obj.arrcats1.push(data.data[i])
    //       else if(i<15 && i>=10)
    //       obj.arrcats2.push(data.data[i])
    //     }
    //     })
  }
  setTrendyType(catlName, id) {
    this.objGlobal.catids = id;
    this.objGlobal.catName = catlName;
  }
  getsubcat(srchtext, id) {
    var obj = this;
    this.objGlobal.srchtexttype = "cat";
    this.objGlobal.srchtext = "";
    this.objGlobal.catids = id;
    this.objGlobal.catName = srchtext;

    obj.objGlobal.filetrcat = [];

    obj.objGlobal.filetrcat.push(id);
    if (
      this.ActivatedRoute.routeConfig.component.name !=
      "TrendyoffersDetailsListComponent"
    )
      this.eventEmitterService.invokecarasoulcomponent();
    this.router.navigate(["/trendyoffersdetails", srchtext]);
    this.eventEmitterService.invokesidebannercomponent();
  }
}

import { Component, OnInit, OnDestroy } from "@angular/core";
import { popularrandService } from "./popularandoffers.service";
import { Subscription } from "rxjs";
import { EventEmitterService } from "../event-emitter.service";
declare var $: any;

@Component({
  selector: "app-popularandoffers",
  templateUrl: "./popularandoffers.component.html",
  styleUrls: ["./popularandoffers.component.css"],
})
export class PopularandoffersComponent implements OnInit, OnDestroy {
  loadingstatus: boolean = false;
  mySlideVideo: any;
  myallSlideVideo: any;
  totalVideo: any = 0;
  startIndex: any = 2;
  title: any = "";
  description: any = "";
  subVideo: Subscription;
  constructor(
    private popularrandService: popularrandService,
    private eventEmitterService: EventEmitterService
  ) {}
  ngOnDestroy() {
    this.subVideo.unsubscribe();
  }
  ngOnInit() {
    var obj = this;
    this.subVideo =
      this.eventEmitterService.invokeVideocomponentFunction.subscribe(() => {
        obj.popularrandService.getVideoData().subscribe((data: any) => {
          if (data.data.length > 0) {
            this.myallSlideVideo = [];
            this.loadingstatus = true;
            this.myallSlideVideo = data.data;
            this.totalVideo = this.myallSlideVideo.length;
            // if(this.totalVideo==1)
            //   obj.mySlideVideo=((data.data).slice(0, 1))
            // else
            //   obj.mySlideVideo=((data.data).slice(0, 2))
            obj.title = obj.myallSlideVideo[0].title;
            obj.description = obj.myallSlideVideo[0].description;
          } else this.loadingstatus = false;
        });
      });

    obj.popularrandService.getVideoData().subscribe((data: any) => {
      if (data.data.length > 0) {
        this.loadingstatus = true;
        this.myallSlideVideo = data.data;
        this.totalVideo = this.myallSlideVideo.length;
        // if(this.totalVideo==1)
        //   obj.mySlideVideo=((data.data).slice(0, 1))
        // else
        //   obj.mySlideVideo=((data.data).slice(0, 2))
        obj.title = obj.myallSlideVideo[0].title;
        obj.description = obj.myallSlideVideo[0].description;

        $(function () {
          setTimeout(function () {
            $("#videoAd").on("slide.bs.carousel", function (data) {
              $(".video-ads").trigger("pause");
              obj.title = data.relatedTarget.id;
              obj.description = data.relatedTarget.title;
              if (data.direction == "left") {
                if (obj.startIndex < obj.totalVideo) {
                  obj.myallSlideVideo.push(obj.myallSlideVideo[obj.startIndex]);
                  obj.startIndex += 1;
                }
              }
            });
          }, 1000);
        });
      } else this.loadingstatus = false;
    });
  }

  // ngAfterViewInit() {
  //   var obj = this;
  // }
}

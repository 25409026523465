import { Component, OnInit, OnDestroy } from "@angular/core";
import { PopularcatService } from "../popularcategories/popularcategories.service";
import { offersservice } from "../offerpage/offerpage.service";
import { ActivatedRoute } from "@angular/router";
import { GlobalService } from "../global.service";
import { EventEmitterService } from "../event-emitter.service";
import { malloffersservice } from "../mall-offers/mall-offers.service";
import { Subscription } from "rxjs";
import { StatService } from "../stats.service";

@Component({
  selector: "app-trendyoffers-details-list",
  templateUrl: "./trendyoffers-details-list.component.html",
  styleUrls: ["./trendyoffers-details-list.component.css"],
})
export class TrendyoffersDetailsListComponent implements OnInit, OnDestroy {
  arrcats: any[];
  arrMall: any[];
  arrPost = new Array();
  startindex: any = 0;
  scrollStatus: any = "Y";
  loadingstatus: boolean = false;
  searchtext: any = "";
  mallFilterstatus: boolean = false;
  catFilterstatus: boolean = false;
  nodataloadingstatus: boolean = false;
  radioSelected: string;
  radiocatSelected: string;
  selected = this.globalService.catName;
  subtrendy: Subscription;
  totalCount: number = 0;
  offers: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90];
  pincode: string;

  constructor(
    private popularcatService: PopularcatService,
    private offersservice: offersservice,
    private ActivatedRoute: ActivatedRoute,
    private globalService: GlobalService,
    private eventEmitterService: EventEmitterService,
    private malloffersservice: malloffersservice,
    private statService: StatService
  ) {
    this.globalService.filetrcat = [];
  }

  ngOnDestroy() {
    this.globalService.filetrcat = [];
    this.globalService.catids = "0";
    this.globalService.catName = "";
    this.globalService.MallName = "";
    this.globalService.mallid = "0";
    this.globalService.catids = "0";
    this.globalService.srchtext = "";
    this.subtrendy.unsubscribe();
  }
  searchbyMall(id) {
    var obj = this;
    obj.globalService.mallid = id;
    this.filterData();
    this.eventEmitterService.invokesidebannercomponent();
    this.eventEmitterService.invokecarasoulcomponent();
    this.eventEmitterService.invokeVideoFunction();
  }
  searchbycat(id) {
    var obj = this;
    if (obj.globalService.filetrcat.indexOf(id) > -1)
      obj.globalService.filetrcat = obj.globalService.filetrcat.filter(
        (item) => item !== id
      );
    else obj.globalService.filetrcat.push(id);
    this.filterData();
    this.eventEmitterService.invokesidebannercomponent();
    this.eventEmitterService.invokecarasoulcomponent();
    this.eventEmitterService.invokeVideoFunction();
  }
  filterData() {
    var obj = this;
    obj.arrPost = [];
    obj.loadingstatus = true;
    obj.startindex = 0;
    obj.nodataloadingstatus = false;
    obj.offersservice
      .getofferData(obj.searchtext, obj.startindex)
      .subscribe((data: any) => {
        this.totalCount = data && data.total_count;
        if (data.data.length > 0) {
          data.data.forEach((element) => {
            var serie = new Array(
              element.web_image,
              element.post_id,
              element.shop_name,
              element.offer_title,
              element.rating,
              element.total_rating,
              element.total_reviews,
              element.expire_date,
              element.shop_address,
              element.rating_color_web,
              element.pack_type
            );
            obj.arrPost.push(serie);
          });
          if (data.data.length < 8) {
            obj.scrollStatus = "N";
            obj.loadingstatus = false;
          } else {
            obj.scrollStatus = "Y";
          }
        } else {
          obj.scrollStatus = "N";
          obj.loadingstatus = false;
          obj.nodataloadingstatus = true;
        }
        //this.arrPost=data.data;
      });
  }
  ngOnInit() {
    var obj = this;
    obj.globalService.filetrcat.push(this.globalService.catids);
    obj.arrPost = [];
    this.malloffersservice.getmallofferData("100").subscribe((data: any) => {
      this.arrMall = data.data;
      this.radioSelected = this.globalService.MallName;
      //element.mall_name,element.mall_id
    });
    this.popularcatService.getpopularcategory("100", obj.globalService.cityid).subscribe((data: any) => {
      this.arrcats = data.data;
    });
    this.subtrendy =
      this.eventEmitterService.invokeTrendyofferlistcomponentFunction.subscribe(
        () => {
          obj.arrPost = [];
          obj.loadingstatus = true;
          obj.nodataloadingstatus = false;
          obj.startindex = 0;
          //obj.ActivatedRoute.params.subscribe(params => {
          // obj.searchtext=params['searchtext'];
          obj.offersservice
            .getofferData(this.globalService.srchtext, obj.startindex)
            .subscribe((data: any) => {
              this.totalCount = data && data.total_count;
              if (data.data.length > 0) {
                data.data.forEach((element) => {
                  var serie = new Array(
                    element.web_image,
                    element.post_id,
                    element.shop_name,
                    element.offer_title,
                    element.rating,
                    element.total_rating,
                    element.total_reviews,
                    element.expire_date,
                    element.shop_address,
                    element.rating_color_web,
                    element.pack_type
                  );
                  obj.arrPost.push(serie);
                });
                if (data.data.length < 8) {
                  obj.scrollStatus = "N";
                  obj.loadingstatus = false;
                } else {
                  obj.scrollStatus = "Y";
                }
              } else {
                obj.scrollStatus = "N";
                obj.loadingstatus = false;
                obj.nodataloadingstatus = true;
              }
              //this.arrPost=data.data;
            });

          //  });
        }
      );
    obj.ActivatedRoute.params.subscribe((params) => {
      obj.arrPost = [];
      obj.startindex = 0;
      this.selected = this.globalService.catName;
      if (obj.globalService.srchtexttype == "cat") obj.searchtext = "";
      else obj.searchtext = params["searchtext"];
      obj.offersservice
        .getofferData(obj.searchtext, obj.startindex)
        .subscribe((data: any) => {
          this.totalCount = data && data.total_count;
          if (data.data.length > 0) {
            data.data.forEach((element) => {
              var serie = new Array(
                element.web_image,
                element.post_id,
                element.shop_name,
                element.offer_title,
                element.rating,
                element.total_rating,
                element.total_reviews,
                element.expire_date,
                element.shop_address,
                element.rating_color_web,
                element.pack_type
              );
              obj.arrPost.push(serie);
            });
            if (data.data.length < 8) {
              obj.scrollStatus = "N";
              obj.loadingstatus = false;
            } else {
              obj.scrollStatus = "Y";
            }
          } else {
            obj.scrollStatus = "N";
            obj.loadingstatus = false;
            obj.nodataloadingstatus = true;
          }
          //this.arrPost=data.data;
        });
    });
  }
  onScroll() {
    this.loadingstatus = true;
    var obj = this;
    this.startindex = this.startindex + 8;

    if (this.scrollStatus == "Y") {
      this.offersservice
        .getofferData(this.searchtext, obj.startindex)
        .subscribe((data: any) => {
          this.totalCount = data && data.total_count;
          if (data.data.length > 0) {
            data.data.forEach((element) => {
              var serie = new Array(
                element.web_image,
                element.post_id,
                element.shop_name,
                element.offer_title,
                element.rating,
                element.total_rating,
                element.total_reviews,
                element.expire_date,
                element.shop_address,
                element.rating_color_web,
                element.pack_type
              );
              obj.arrPost.push(serie);
            });
            if (data.data.length < 8) {
              obj.scrollStatus = "N";
              obj.loadingstatus = false;
            } else {
              obj.scrollStatus = "Y";
            }
          } else {
            obj.scrollStatus = "N";
            obj.loadingstatus = false;
            // obj.nodataloadingstatus=true;
          }
          //obj.loadingstatus=false;
        });
    } else {
      this.loadingstatus = false;
    }
  }

  searchbyOffer(offer: string) {
    var obj = this;
    obj.globalService.offer = offer;
    this.filterData();
    this.eventEmitterService.invokesidebannercomponent();
    this.eventEmitterService.invokecarasoulcomponent();
    this.eventEmitterService.invokeVideoFunction();
  }

  searchPinCode() {
    var obj = this;
    obj.globalService.pincode = obj.pincode;
    this.filterData();
    this.eventEmitterService.invokesidebannercomponent();
    this.eventEmitterService.invokecarasoulcomponent();
    this.eventEmitterService.invokeVideoFunction();
  }

  postStas(moduleId: number) {
    this.statService.getClientIp().subscribe((data: any) => {
      this.statService.postStoreVisitStats(data.ip, 3, moduleId).subscribe(data => { })
    })
  }

  resetFilter() {
    window.location.reload();
  }

  get showResetButton() {
    return this.pincode || this.radioSelected || this.selected !== "0"
  }
}

import { Component, OnInit } from '@angular/core';
import { malldetailswihtoutrelatedcontentService } from './mall-details-wiht-out-relatedcontent.service';
import { ActivatedRoute } from '@angular/router'
@Component({
  selector: 'app-mall-details-wiht-out-relatedcontent',
  templateUrl: './mall-details-wiht-out-relatedcontent.component.html',
  styleUrls: ['./mall-details-wiht-out-relatedcontent.component.css']
})
export class MallDetailsWihtOutRelatedcontentComponent implements OnInit {
  myShopDetails=[];
  myShopPost:any;
  constructor(private malldetailswihtoutrelatedcontentService:malldetailswihtoutrelatedcontentService,
    private ActivatedRoute:ActivatedRoute) { }

  ngOnInit() {
    var curobj=this;
    this.ActivatedRoute.params.subscribe(params => {
      this.malldetailswihtoutrelatedcontentService.getMallDetailsData(params['id']).subscribe((data:any)=>{
        this.myShopDetails.push(data.data[0]);
        this.myShopPost=data.data[0].posts;
      })
      });
    
   
  }

}

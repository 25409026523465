import {
  Component,
  Inject,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  ChangeDetectionStrategy,
  Input,
} from "@angular/core";
import { headerService } from "./header.service";
import { PopularcatService } from "../popularcategories/popularcategories.service";
import { Options } from "ng5-slider";
import { GlobalService } from "../global.service";
import "rxjs/add/observable/merge";
import "rxjs/add/operator/map";
import { EventEmitterService } from "../event-emitter.service";
import { DataService } from "../data.service";
import { Subscription, Observable } from "rxjs";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { Router, ActivatedRoute } from "@angular/router";
import { addlistingServiceH } from "../addlisting/addlistingH.service";
import * as $ from "jquery";
import Swal from "sweetalert2";
import { MatDialog } from "@angular/material";
import { LoginComponent } from "../login/login.component";
declare var $: any;
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  ShopName: string = "";
  ShopCategory: string = "";
  ContactPersonName: string = "";
  ContactNo: string = "";
  Email: string = "";
  arrcats: any[];
  userId;

  options: Options = {
    floor: 0,
    ceil: 250,
    showSelectionBar: true,
    getPointerColor: (value: number): string => {
      return "#FF0000";
    },
    getSelectionBarColor: (value: number): string => {
      return "#FF0000";
    },
  };
  minValue: number = this.options.floor;
  maxValue: number = this.options.ceil;
  keyword = "city_name";
  data: any = [];
  cityname: any = "";
  searchtext = "";
  searchkeyword = "name";
  subfilter: Subscription;
  subCity: Subscription;
  searchdata: any = [];
  searchname: any = "";
  loaderstatus: boolean = false;

  category: any;
  shopname: any;
  cntname: any;
  mobile: any;
  email: any;
  btnstatus: boolean = true;
  btnText = "Submit";
  arrcats1: any;
  categoryId: any = "";
  message1: any = "";
  constructor(
    public dialog: MatDialog,
    private headerService: headerService,
    private popularcatService: PopularcatService,
    private objGlobal: GlobalService,
    private eventEmitterService: EventEmitterService,
    private ref: ChangeDetectorRef,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private router: Router,
    private ActivatedRoute: ActivatedRoute,
    public addlistingService: addlistingServiceH
  ) {}
  ngOnDestroy() {
    this.subfilter.unsubscribe();
    this.subCity.unsubscribe();
  }
  changecategory(catId) {
    this.categoryId = catId;
  }
  getCat() {
    var obj = this;
    this.arrcats1 = obj.objGlobal.categorylist;
  }
  ngOnInit() {
    this.userId = localStorage.getItem("tokenId");
    var obj = this;
    this.popularcatService
      .getpopularcategory("100", obj.objGlobal.cityid)
      .subscribe((data: any) => {
        this.arrcats = data.data;
        obj.objGlobal.categorylist = data.data;
        this.eventEmitterService.invokeCategorybodyFunction(this.arrcats);
        this.eventEmitterService.invokeCategoryfooterFunction(
          this.arrcats.slice(0, 23)
        );
      });
    this.subCity =
      this.eventEmitterService.invokeselectcitycomponentFunction.subscribe(
        () => {
          $("#selectLocation").modal("hide");
          $("#selectLocation2").modal("hide");
          this.router.navigate(["/"]);
          // obj.eventEmitterService.invokecarasoulcomponent();
          // obj.eventEmitterService.invokesidebannercomponent();
          // obj.eventEmitterService.invokepostcomponent();
          // obj.eventEmitterService.invokeMalloffercomponent();
          // obj.eventEmitterService.invokeMallofferlistFunction();
          // obj.eventEmitterService.invokeTrendyofferFunction();
          // this.eventEmitterService.invokeTrendyofferlistFunction();
        }
      );
    this.subfilter =
      this.eventEmitterService.invokefiltercomponentFunction.subscribe(
        (name: string) => {
          obj.data = [];
          obj.data = obj.objGlobal.citylist;
          let indx = obj.data.findIndex(
            (item) => item.city_name.toLowerCase() == name.toLowerCase()
          );
          obj.cityname = name;
          if (indx != -1) {
            obj.objGlobal.cityName = obj.data[indx].city_name;
            obj.objGlobal.cityid = obj.data[indx].city_id;
          }
          obj.eventEmitterService.invokecarasoulcomponent();
          obj.eventEmitterService.invokesidebannercomponent();
          obj.eventEmitterService.invokepostcomponent();
          obj.eventEmitterService.invokeMalloffercomponent();
          obj.eventEmitterService.invokeMallofferlistFunction();
          obj.eventEmitterService.invokeTrendyofferFunction();
          obj.eventEmitterService.invokeTrendyofferlistFunction();
          obj.eventEmitterService.invokeVideoFunction();
          obj.eventEmitterService.invokeChatBotFunction();
        }
      );

    obj.data = obj.objGlobal.citylist;
    let indx = obj.data.findIndex(
      (item) =>
        item.city_name.toLowerCase() == obj.objGlobal.cityName.toLowerCase()
    );
    if (indx == -1) {
      obj.cityname = obj.objGlobal.cityName;
    } else {
      obj.cityname = obj.data[indx].city_name;
    }
    obj.searchname = this.objGlobal.srchtext;

    //obj.data

    // $(document).ready(function() {
    //     $('#selectLocation').modal('show');
    // });
  }

  selectEvent(item) {
    //city_id: 58, city_name: "ANGUL"

    if (item.city_id == undefined) {
      let indx = this.data.findIndex(
        (item) =>
          item.city_name.toLowerCase() == this.objGlobal.cityName.toLowerCase()
      );
      if (indx != -1) {
        this.objGlobal.cityName = this.data[indx].city_name;
        this.objGlobal.cityid = this.data[indx].city_id;
      }
    } else {
      this.objGlobal.cityName = item.city_name;
      this.objGlobal.cityid = item.city_id;
      this.eventEmitterService.invokecarasoulcomponent();
      this.eventEmitterService.invokesidebannercomponent();
      this.eventEmitterService.invokepostcomponent();
      this.eventEmitterService.invokeMalloffercomponent();
      this.eventEmitterService.invokeMallofferlistFunction();
      this.eventEmitterService.invokeTrendyofferFunction();
      this.eventEmitterService.invokeTrendyofferlistFunction();
      this.eventEmitterService.invokeVideoFunction();
      this.eventEmitterService.invokeChatBotFunction();
      this.router.navigate(["/"]);
    }
    this.storage.set("locationname", this.objGlobal.cityName);
    this.storage.set("locationid", this.objGlobal.cityid);

    // do something with selected item
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  //  onFocused(e){

  //    // do something when input is focused
  //  }
  setTrendyType(catlName, id) {
    this.objGlobal.catids = id;
    this.objGlobal.catName = catlName;
  }
  selectSearchEvent(item) {
    if (item.name == undefined) this.objGlobal.srchtext = item;
    else this.objGlobal.srchtext = item.name;
    if (
      this.ActivatedRoute.routeConfig.component.name !=
      "TrendyoffersDetailsListComponent"
    )
      this.eventEmitterService.invokecarasoulcomponent();
    this.router.navigate(["/trendyoffersdetails", this.objGlobal.srchtext]);
    // if(item.name==undefined){

    //   this.router.navigate([ '/trendyoffersdetails',item]);
    // }
    // else{

    //   this.router.navigate([ '/trendyoffersdetails',item.name]);
    // }

    this.eventEmitterService.invokesidebannercomponent();
    // this.eventEmitterService.invokeTrendyofferlistFunction();
  }
  onChangeSearchtext(val: string) {
    var objthis = this;
    if (val.length >= 3) {
      objthis.loaderstatus = true;
      let obj = {
        method: "citizen_text_autocomplete",
        text: val,
        city_id: this.objGlobal.cityid,
      };
      this.headerService.searchextData(obj).subscribe((data: any) => {
        objthis.loaderstatus = false;
        objthis.searchdata = data.data;
      });
    } else {
      objthis.loaderstatus = false;
    }

    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  //  onFocusedSearch(e){

  //    // do something when input is focused
  //  }
  onInputCleared(e) {
    this.objGlobal.srchtext = "";
    // do something when input is focused
  }
  search(srchtext) {
    if (srchtext != "") {
      this.objGlobal.srchtext = srchtext;
      if (
        this.ActivatedRoute.routeConfig.component.name !=
        "TrendyoffersDetailsListComponent"
      )
        this.eventEmitterService.invokecarasoulcomponent();
      this.router.navigate(["/trendyoffersdetails", srchtext]);
      //this.router.navigate([ '/trendyoffersdetails',srchtext]);

      this.eventEmitterService.invokesidebannercomponent();
      // this.eventEmitterService.invokeTrendyofferlistFunction();
      // do something when input is focused
    }
  }
  getsubcat(srchtext, id) {
    var obj = this;
    this.objGlobal.srchtexttype = "cat";
    this.objGlobal.srchtext = "";
    this.objGlobal.catids = id;
    this.objGlobal.catName = srchtext;

    obj.objGlobal.filetrcat = [];

    obj.objGlobal.filetrcat.push(id);
    if (
      this.ActivatedRoute.routeConfig.component.name !=
      "TrendyoffersDetailsListComponent"
    )
      this.eventEmitterService.invokecarasoulcomponent();
    this.router.navigate(["/trendyoffersdetails", srchtext]);
    this.eventEmitterService.invokesidebannercomponent();
  }
  register() {
    this.btnstatus = false;
    this.btnText = "Please wait..";
    var objthis = this;
    let obj = {
      method: "citizen_register_as_shop",
      category_id: this.categoryId,
      shop_name: this.shopname,
      contact_person: this.cntname,
      mobile: this.mobile,
      email: this.email,
      message: this.message1,
    };
    this.addlistingService.addListiningRegister(obj).subscribe((data: any) => {
      if (data.responseCode == 200) {
        Swal.fire({
          type: "success",
          text: data.message,
        }).then(function (result) {});
      } else {
        Swal.fire({
          type: "error",
          text: data.message,
        }).then(function (result) {});
      }
      objthis.btnstatus = true;
      objthis.btnText = "Submit";
      this.category = "";
      this.shopname = "";
      this.cntname = "";
      this.mobile = "";
      this.email = "";
    });
  }

  openLoginDialog(): void {
    this.dialog.open(LoginComponent, { width: "400px" });
  }

  logout() {
    localStorage.clear();
    window.location.reload();
  }

  openUrlInNewTab(): void {
    window.open("https://biggdiscount.com/shopowner/", "_blank");
  }
}

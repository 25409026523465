import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export type homeStatInput = {
  method: string,
  ip_address: string,
  homepage: number
};

@Injectable({
  providedIn: 'root'
})
export class StatService {
  private url: string;

  constructor(private http: HttpClient) {
    this.url = environment.API_END_POINT;
  }

  getClientIp() {
    return this.http.get("https://api.ipify.org/?format=json");
  }

  postHomepageStats(ip: string): Observable<any> {
    return this.http.post<any>(this.url, {
      method: "store_stats",
      ip_address: ip,
      homepage: 1
    });
  }

  postStoreVisitStats(ip: string, module_type: number, module_id: number): Observable<any> {
    return this.http.post<any>(this.url, {
      method: "store_stats",
      ip_address: ip,
      module_type: module_type,
      module_id: module_id
    });
  }

  postCategoryStats(ip: string, category_id: number): Observable<any> {
    return this.http.post<any>(this.url, {
      method: "store_stats",
      ip_address: ip,
      category_id: category_id
    });
  }
}

import { Injectable } from '@angular/core';

@Injectable()
export class GlobalService {
    public cityid: string = '41';
    public catid: string = '0';
    public catName: string = '0';
    public mallid: string = '0';
    public offer: string = "";
    public pincode: string = "";
    public citylist: any = [];
    public cityName: string = 'BHUBANESWAR';
    public lat: string = "";
    public long: string = "";
    public shopId: string = "";
    public MallName: string = "";
    public trendyType: string = "";
    public filetrcat: any = [];
    public catids: any = "0";
    public srchtext: string = "";
    public srchtexttype: string = "";
    public footercounter: boolean = false;
    public categorylist: any = [];
}
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-registerbody',
  templateUrl: './registerbody.component.html',
  styleUrls: ['./registerbody.component.css']
})
export class RegisterbodyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

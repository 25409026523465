import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

const blog = {
  id: 14,
  author_id: 12,
  title: "sa dasd a",
  content: "sa das dasd&nbsp;\r\n\r\n",
  created_at: "2023-08-29 20:26:43",
};

const fullBLog = {
  responseCode: "200",
  data: {
    id: 1,
    author_id: 12,
    title: "as dasd asd asd V",
    summary: null,
    content: "",
    published: 0,
    delete_flag: 0,
    created_at: "2023-08-28 16:41:14",
    updated_at: "2023-08-28 17:36:19",
    comments: [
      {
        id: 4,
        blog_id: 1,
        name: "Anil Sethy",
        comment: "Awesome",
        created_at: "2023-08-28 19:50:44",
        updated_at: "2023-08-28 19:50:44",
        reply_comments: [
          {
            name: "Manoj",
            comment: "Its fine",
            created_at: "2023-09-01 16:51:27",
          },
          {
            name: "Sasmita Mallick",
            comment: "Awesome...",
            created_at: "2023-09-01 16:50:28",
          },
        ],
      },
    ],
  },
};

const blogList = {
  responseCode: "200",
  blogs: {
    current_page: 1,
    data: [
      {
        id: 7,
        author_id: 12,
        title: "asd asdsadas dasd",
        content:
          "Where does it come from?\r\n\r\nContrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum pa......",
        created_at: "2023-08-28 18:50:05",
      },
      {
        id: 6,
        author_id: 12,
        title: "sa dwfwwdsad asd asd asd",
        content:
          "Where does it come from?\r\n\r\nContrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum pa......",
        created_at: "2023-08-28 18:49:58",
      },
      {
        id: 5,
        author_id: 12,
        title: "sadasdf d asd asd as",
        content:
          "Where does it come from?\r\n\r\nContrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum pa......",
        created_at: "2023-08-28 18:49:51",
      },
    ],
    first_page_url: "http://localhost/biggdiscount/portal/api/website?page=1",
    from: 1,
    last_page: 1,
    last_page_url: "http://localhost/biggdiscount/portal/api/website?page=1",
    next_page_url: null,
    path: "http://localhost/biggdiscount/portal/api/website",
    per_page: 100,
    prev_page_url: null,
    to: 14,
    total: 14,
  },
};

export type blogCommentInput = {
  blog_id: number;
  name: string;
  comment: string;
  comment_id?: number;
};

export type blogReplyCommentInput = {
  blog_id: number;
  comment_id: number;
  name: string;
  reply: string;
};

@Injectable({
  providedIn: "root",
})
export class BlogService {
  private url: string;

  constructor(private httpClient: HttpClient) {
    this.url = environment.API_END_POINT;
  }

  getBlogList(): Observable<typeof blogList> {
    return this.httpClient.post<typeof blogList>(this.url, {
      method: "get_all_blog",
    });
  }

  getBlogById(blogId: number): Observable<typeof fullBLog> {
    return this.httpClient.post<typeof fullBLog>(this.url, {
      method: "get_blog_details",
      blog_id: blogId,
    });
  }

  postBlogComment(payload: blogCommentInput): Observable<any> {
    return this.httpClient.post<any>(this.url, {
      ...payload,
      method: "blog_comment",
    });
  }

  postBlogReplyComment(payload: blogReplyCommentInput): Observable<any> {
    return this.httpClient.post<any>(this.url, {
      ...payload,
      method: "blog_comment_reply",
    });
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shopdetailsbody',
  templateUrl: './shopdetailsbody.component.html',
  styleUrls: ['./shopdetailsbody.component.css']
})
export class ShopdetailsbodyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit, OnDestroy } from "@angular/core";
import { offersservice } from "./offerpage.service";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { EventEmitterService } from "../event-emitter.service";
import { StatService } from "../stats.service";
@Component({
  selector: "app-offerpage",
  templateUrl: "./offerpage.component.html",
  styleUrls: ["./offerpage.component.css"],
})
export class OfferpageComponent implements OnInit, OnDestroy {
  arrcats = new Array();
  constructor(
    private offersservice: offersservice,
    private ActivatedRoute: ActivatedRoute,
    private eventEmitterService: EventEmitterService,
    private statService: StatService
  ) {}
  startindex: any = 0;
  scrollsize: any = 2;
  subtrendy: Subscription;
  scrollStatus: any = "Y";
  loadingstatus: boolean = false;
  loadingfakestatus: boolean = true;
  totalCount: number = 0;
  ngOnDestroy() {
    this.subtrendy.unsubscribe();
  }
  ngOnInit() {
    var obj = this;
    this.startindex = 0;
    this.subtrendy =
      this.eventEmitterService.invokeTrendyoffercomponentFunction.subscribe(
        () => {
          obj.arrcats = [];
          obj.scrollStatus = "Y";
          this.startindex = 0;
          obj.ActivatedRoute.params.subscribe((params) => {
            obj.offersservice
              .getofferData("", obj.startindex)
              .subscribe((data: any) => {
                this.totalCount = data && data.total_count;
                if (data.data.length > 0) {
                  data.data.forEach((element) => {
                    var serie = new Array(
                      element.web_image,
                      element.post_id,
                      element.shop_name,
                      element.offer_title,
                      element.rating,
                      element.total_rating,
                      element.total_reviews,
                      element.expire_date,
                      element.shop_address,
                      element.rating_color_web,
                      element.pack_type
                    );
                    obj.arrcats.push(serie);
                  });
                  obj.loadingstatus = true;
                  obj.loadingfakestatus = false;
                } else {
                  obj.scrollStatus = "N";
                  obj.loadingstatus = false;
                  obj.loadingfakestatus = true;
                }
                //this.arrcats=data.data;
                //obj.scrollsize=obj.scrollsize+(obj.arrcats.length)
              });
          });
        }
      );

    this.ActivatedRoute.params.subscribe((params) => {
      this.offersservice
        .getofferData("", this.startindex)
        .subscribe((data: any) => {
          this.totalCount = data && data.total_count;
          if (data.data.length > 0) {
            data.data.forEach((element) => {
              var serie = new Array(
                element.web_image,
                element.post_id,
                element.shop_name,
                element.offer_title,
                element.rating,
                element.total_rating,
                element.total_reviews,
                element.expire_date,
                element.shop_address,
                element.rating_color_web,
                element.pack_type
              );
              this.arrcats.push(serie);
            });
            obj.loadingstatus = true;
            obj.loadingfakestatus = false;
          } else {
            this.scrollStatus = "N";
            obj.loadingstatus = false;
            obj.loadingfakestatus = true;
          }
          //this.arrcats=data.data;
          //this.scrollsize=this.scrollsize+(this.arrcats.length)
        });
    });
  }
  onScroll() {
    // this.loadingstatus=true;
    // var obj=this;
    // this.startindex=(this.startindex)+4;
    // if(this.scrollStatus=="Y"){
    // this.offersservice.getofferData("",obj.startindex).subscribe((data:any)=>{
    //   if(data.data.length>0){
    //     data.data.forEach(element => {
    //       var serie = new Array(element.web_image, element.post_id,element.shop_name,element.offer_title,element.rating);
    //       this.arrcats.push(serie);
    //     });
    //   }
    //   else{
    //     this.scrollStatus="N"
    //   }
    //   this.loadingstatus=false;
    //   })
    // }
    // else{
    //   this.loadingstatus=false;
    // }
  }

  postStas(moduleId: number) {
    this.statService.getClientIp().subscribe((data: any) => {
      this.statService.postStoreVisitStats(data.ip, 3, moduleId).subscribe(data => { })
    })
  }
}

import { Injectable } from '@angular/core';
import {
        HttpInterceptor, HttpRequest,
        HttpHandler, HttpEvent, HttpErrorResponse
    } from '@angular/common/http';
import { Observable, throwError,timer } from 'rxjs';
import { catchError,mergeMap,retryWhen,take  } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor{
     constructor() {  
      
     }
     handleError(error: HttpErrorResponse){
          return throwError(error);
     }
    intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>>{
     return next.handle(req)
         .pipe(
         retryWhen(errors => {
            return errors
              .pipe(
                mergeMap(error=>error.status === 429?timer(0):throwError(error)),
                take(2)
              )
          }),
          catchError(this.handleError)
         )
     };
}
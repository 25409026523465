import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../login/login.service';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {
  profileForm: FormGroup;
  isLoading: boolean = false;
  messages

  constructor(private formBuilder: FormBuilder, private loginService: LoginService, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.setForm(this.data);
  }

  setForm(data: any) {
    this.profileForm = this.formBuilder.group({
      name: [data.name, [Validators.required]],
      gender: [data.gender.toString(), [Validators.required]],
      address: [data.address, [Validators.required]],
      profile: []
    });
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.profileForm.get('profile').setValue(file);
    }
  }

  get formControl() {
    return this.profileForm.controls;
  }

  onSubmit() {
    const formData = new FormData();
    formData.append('name', this.profileForm.get('name').value);
    formData.append('gender', this.profileForm.get('gender').value);
    this.profileForm.get('profile').value ? formData.append('profile_pic', this.profileForm.get('profile').value) : '';
    formData.append('address', this.profileForm.get('address').value);
    formData.append('user_id', localStorage.getItem("tokenId"));
    formData.append('method', "edit_profile_user");
    this.loginService.editProfile(formData).subscribe((response: any) => {
      this.isLoading = false;
      if (response && response.errors) {
        this.messages = response.errors
      } else {
        this.messages = [response ? response.message : ''];
        window.location.reload();
      }
    }, error => {
      this.isLoading = false;
    })
  }
}

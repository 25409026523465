import { Component, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { GlobalService } from './components/global.service';
import { EventEmitterService } from './components/event-emitter.service';
import { Router, NavigationEnd } from '@angular/router';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { selectlocationService } from './components/select-location/select-location.service';
import { DynamicContentService } from './components/dynamic-content.service';

declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'biggdiscount';
  loading: boolean = false;
  showChatBot: boolean = false;
  selectedLocation: string = "";
  @ViewChild('dynamicContainer', { read: ViewContainerRef, static: false }) dynamicContainer: ViewContainerRef;

  constructor(
    private objGlobal: GlobalService,
    private eventEmitterService: EventEmitterService,
    private router: Router,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private selectlocationService: selectlocationService,
    private dynamicContentService: DynamicContentService
  ) { }

  public ngOnInit(): void {
    this.selectedLocation = localStorage.getItem("locationname");
    this.objGlobal.footercounter = true
    this.selectlocationService.getDemographyData().subscribe((data: any) => {
      this.objGlobal.citylist = data.data;
      this.loading = true
      if (this.storage.get("locationname") == undefined || this.storage.get("locationname") == null || this.storage.get("locationname") == "") {
        $('#selectLocation2').modal('show');
      }
      else {
        this.objGlobal.cityName = this.storage.get("locationname")
        this.objGlobal.cityid = this.storage.get("locationid")
        this.eventEmitterService.invokefiltercomponent(this.storage.get("locationname"));
        this.eventEmitterService.invokeChatBotComponentFunction.subscribe(() => {
          this.selectedLocation = this.objGlobal.cityName;
        })
      }
    });

    this.eventEmitterService.invokeChatBotAction.subscribe((data: any) => {
      this.addDynamicComponent(data);
    })


    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }

  toogleChatButton() {
    this.showChatBot = !this.showChatBot;
    if (this.showChatBot) {
      setTimeout(() => {
        this.addDynamicComponent(1);
      }, 10);
    }
  }

  addDynamicComponent(type: number) {
    this.dynamicContentService.createDynamicComponent(this.dynamicContainer, type);
  }
}

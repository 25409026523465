import { Component, OnInit } from '@angular/core';
import { contactform } from './contactform.service';
import { EventEmitterService } from '../event-emitter.service';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-contactform',
  templateUrl: './contactform.component.html',
  styleUrls: ['./contactform.component.css']
})
export class ContactformComponent implements OnInit {
  custName:string="";
  email:string="";
  subject:string="";
  message:string="";
  mobile:string="";
  activeOfc:any = 'ho';
  btnstatus:boolean=true
  btnText="Submit"
  constructor(public contactform: contactform,private eventEmitterService: EventEmitterService) { }

  ngOnInit() {
  }
  openMap(lat,long,ofcLoc) {
    this.activeOfc = ofcLoc;
    var latlong=lat+","+long
    this.eventEmitterService.invokemapFunction(latlong);
  }
  contact() {
    this.btnstatus=false
    this.btnText="Please wait.."
    var pagObj=this;
    let obj={
      "method":"citizen_post_feedback",
      "name":this.custName,
      "email":this.email,
      "mobile":this.mobile,
      "subject":this.subject,
      "feedback":this.message,
    }
    this.contactform.contact(obj).subscribe((data:any)=>{
      if(data.responseCode==200)
      {
       Swal.fire({
         type: 'success',
         text:  data.message
       }).then(function(result){
        
       })
      }
     else{
       Swal.fire({
         type: 'error',
         text:  data.message
       }).then(function(result){
         
       })
     }
     this.custName=""
     this.email=""
     this.subject=""
     this.message=""
     this.mobile=""
     pagObj.btnstatus=true
     pagObj.btnText="Submit"
    })
   

  }
}

import { Injectable } from '@angular/core';//injectable represents it is a service
import { HttpClient } from '@angular/common/http';//to do http calls we have to import httpclient from angularhttp
import { environment } from '../../../environments/environment';//to get base url to connect the server
import { GlobalService } from '../global.service';


//service decotator
@Injectable()
export class CarouselService {
    sessionUser:any;
  //base host in api
  private host = environment.API_END_POINT;
  //url
  private url: string = '';

  constructor(private http: HttpClient,private objGlobal: GlobalService) { 

  }
  getCarouselData(){
    const user_id = localStorage.getItem("tokenId");
    var data:any = {
        "method":"citizen_banner_ads",
        "city_id":this.objGlobal.cityid,
        "category_id":this.objGlobal.catids,
        "mall_id": this.objGlobal.mallid,
        "srch_text":this.objGlobal.srchtext,
        offer: this.objGlobal.offer,
        pincode: this.objGlobal.pincode
      } ;
    user_id ? data.user_id = user_id : ''
      this.url = this.host;
    return this.http.post(this.url,data);
  }

  bookmarkOffer(bannerId, bookmark) {
    const user_id = localStorage.getItem("tokenId");
    var data = {
      method: "store_bookmark",
      module_type: 1,
      module_id: bannerId,
      bookmark,
      user_id
    };
    this.url = this.host;
    return this.http.post(this.url, data);
  }
}


import { Component, OnInit } from '@angular/core';
import { StarRatingComponent } from 'ng-starrating';
import { ActivatedRoute } from '@angular/router';
import { reviewcontent } from './reviewcontent.service';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-reviewcontent',
  templateUrl: './reviewcontent.component.html',
  styleUrls: ['./reviewcontent.component.css']
})
export class ReviewcontentComponent implements OnInit {
  rating:number=4;
  name:any="";
  email:any="";
  desc:any="";
  btnstatus:boolean=true
  btnText="Post Comment"
  constructor(private ActivatedRoute:ActivatedRoute,private reviewcontent:reviewcontent) { }

  ngOnInit() {
  }
  submitReview() {
    this.btnstatus=false
    this.btnText="Please wait.."
    var pagObj=this;
    this.ActivatedRoute.params.subscribe(params => {
       let obj={
         "method":"citizen_save_comments",
         "post_id": params['post_id'],
         "email_id":this.email,
         "user_id":this.name,
         "rating":this.rating,
         "comment":this.desc
       }
       this.reviewcontent.review(obj).subscribe((data:any)=>{
        this.email="";
        this.name="";
        this.rating=0;
        this.desc="";
        if(data.responseCode==200)
        {
         Swal.fire({
           type: 'success',
           text:  data.message
         }).then(function(result){
         
         })
        }
       else{
         Swal.fire({
           type: 'error',
           text:  data.message
         }).then(function(result){
           
         })
       }
       pagObj.btnstatus=true
       pagObj.btnText="Post Comment"
       })
     });
  }
  onRate($event:{oldValue:number, newValue:number, starRating:StarRatingComponent}) {
    this.rating=$event.newValue;
    // alert(`Old Value:${$event.oldValue}, 
    //   New Value: ${$event.newValue}, 
    //   Checked Color: ${$event.starRating.checkedcolor}, 
    //   Unchecked Color: ${$event.starRating.uncheckedcolor}`);
  }

}

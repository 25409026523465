import { Component, OnInit, ChangeDetectorRef, OnDestroy, HostListener } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CarouselService } from './carousel.service';
import { ActivatedRoute, Router } from '@angular/router'
import * as $ from "jquery";
import { EventEmitterService } from '../event-emitter.service';
import { GlobalService } from '../global.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material';
import { LoginComponent } from '../login/login.component';
@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit, OnDestroy {
  loadingstatus: boolean = false
  loadingfakestatus: boolean = true
  title = 'owl-carousel';
  mySlideImages = [];
  pageName: any = '';
  innerWidth: any = 0;
  hideAds: any = true;

  mySlideOptions = { items: 2, dots: true, nav: true, autoplay: true, margin: 10, loop: true, autoplayTimeout: 7000 };
  subbanner: Subscription;
  imagesList = [];
  bookmark: boolean = false;
  userId;

  constructor(public dialog: MatDialog, public router: Router, private carouselService: CarouselService, private ref: ChangeDetectorRef,
    private ActivatedRoute: ActivatedRoute, private eventEmitterService: EventEmitterService,
    private objGlobal: GlobalService) {

  }
  ngOnDestroy() {
    this.subbanner.unsubscribe();
  }
  ngOnInit() {
    this.userId = localStorage.getItem("tokenId");

    var catid = ""
    var obj = this;
    this.subbanner = this.eventEmitterService.invokecarasoulcomponentFunction.subscribe(() => {
      obj.carouselService.getCarouselData().subscribe((data: any) => {
        obj.mySlideImages = []
        if (data.data.length > 0) {
          data.data.forEach(element => {
            obj.mySlideImages.push({ packType: element.pack_type, image: element.web_pic, bookmarked: element.has_bookmarked, id: element.id });
          });
          obj.loadingstatus = true
          obj.loadingfakestatus = false


        }

      })
    });

    this.ActivatedRoute.params.subscribe(params => {
      this.mySlideImages = []
      if (params['catid'] != null || params['catid'] != undefined)
        this.objGlobal.catid = params['catid']
      else
        this.objGlobal.catid = "0"

      this.carouselService.getCarouselData().subscribe((data: any) => {
        if (data.data.length > 0) {
          data.data.forEach(element => {
            this.mySlideImages.push({ packType: element.pack_type, image: element.web_pic, bookmarked: element.has_bookmarked, id: element.id });
          });
          obj.loadingstatus = true
          obj.loadingfakestatus = false

        }
      })


    });

    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 480) {
      this.hideAds = true;
    }
    else {
      this.hideAds = false;
    }
    this.pageName = this.router.url;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 480) {
      this.hideAds = true;
    }
    else {
      this.hideAds = false;
    }
  }

  bookmarkImage(item) {
    if (!this.userId) {
      this.dialog.open(LoginComponent, { width: "400px" });
    } else {
      item.bookmarked = !item.bookmarked;
      this.carouselService.bookmarkOffer(item.id, item.bookmarked).subscribe();
    }
  }

}

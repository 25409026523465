// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  BASE_API_END_POINT: "https://biggdiscount.com/portal/api",
  API_END_POINT: "https://biggdiscount.com/portal/api/website",
  API_END_POINT_LOCAL: "http://localhost/biggdiscount/portal/api/website",
  LOCATION_API_END_POINT: "https://biggdiscount.com/portal/api/locationapi",
  CATEGORY_API_END_POINT: "https://biggdiscount.com/portal/api/citizen_all_category/100",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Component, OnInit,Inject } from '@angular/core';
import { GlobalService } from '../global.service';
import { EventEmitterService } from '../event-emitter.service';
import { selectlocationService } from './select-location.service';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
@Component({
  selector: 'app-select-location',
  templateUrl: './select-location.component.html',
  styleUrls: ['./select-location.component.css']
})
export class SelectLocationComponent implements OnInit {
  cityObj:any = [];
  keyword = 'city_name';
  data:any=[];
  cityname:any = '';


  constructor(private objGlobal: GlobalService,private selectlocationService: selectlocationService,
    private eventEmitterService: EventEmitterService,@Inject(LOCAL_STORAGE) private storage: StorageService) { }

  ngOnInit() {
    // console.log(this.objGlobal.citylist)
      this.data=this.objGlobal.citylist
      this.cityObj=this.objGlobal.citylist
    // this.selectlocationService.getDemographyData().subscribe((data:any)=>{
    //   this.objGlobal.citylist=data.data;
    //   this.data=data.data
    //   this.cityObj=data.data
     

    // });
    // this.data=this.objGlobal.citylist;
    // this.cityObj=this.objGlobal.citylist;
  }
  selectEvent(item) {
    if(item.city_id==undefined){
     let indx =this.data.findIndex(item=>(item.city_name).toLowerCase() == (this.objGlobal.cityName).toLowerCase());
     if(indx!=-1){
     this.objGlobal.cityName=this.data[indx].city_name
     this.objGlobal.cityid=this.data[indx].city_id
     }
    }else{
     this.objGlobal.cityName=item.city_name
     this.objGlobal.cityid=item.city_id
    }
    this.eventEmitterService.invokefiltercomponent(item.city_name);
    this.eventEmitterService.invokeselectcitycomponent();
    this.storage.set("locationname", this.objGlobal.cityName);
    this.storage.set("locationid", this.objGlobal.cityid);
     // do something with selected item
   }
  
   onChangeSearch(val: string) {
   }
   
   onFocused(e){
     
   }
   setLocation(id,name) {
    this.objGlobal.cityName=name
     this.objGlobal.cityid=id
     this.eventEmitterService.invokefiltercomponent(name);
     this.eventEmitterService.invokeselectcitycomponent();
     this.storage.set("locationname", name);
     this.storage.set("locationid", id);
  }
}

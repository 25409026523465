import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SignupDialogComponent } from "../signup-dialog/signup-dialog.component";
import { joinusonhome } from "./join-us-on-home.service";
import { GlobalService } from "../global.service";
import Swal from "sweetalert2";
import { catchError, tap } from "rxjs/operators";
declare var $: any;
@Component({
  selector: "app-join-us-on-home",
  templateUrl: "./join-us-on-home.component.html",
  styleUrls: ["./join-us-on-home.component.css"],
})
export class JoinUsOnHomeComponent implements OnInit {
  animal: string;
  name: string;
  custName: string = "";
  custemail: string = "";
  custmobile: string = "";
  btnstatus: boolean = true;
  btnText = "Subscribe Now";
  arrcats: any;
  selectedCat: any = [];
  // realTimeStats: Record<string, string | number> = {};

  constructor(
    public dialog: MatDialog,
    public joinusonhome: joinusonhome,
    private objGlobal: GlobalService
  ) {
  }

  openDialog(): void {
    // alert(4)
    const dialogRef = this.dialog.open(SignupDialogComponent, {
      width: "250px",
      data: { name: this.name, animal: this.animal },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.animal = result;
    });
  }
  getCat() {
    var obj = this;
    this.arrcats = obj.objGlobal.categorylist;
  }
  ngOnInit() {
    var obj = this;

    // alert(this.objGlobal.footercounter)
    $(function () {
      var topVal = $(".parent-div").offset().top - $(window).height() / 2;
      // alert(obj.objGlobal.footercounter)
      var playCount = obj.objGlobal.footercounter;
      $(window).scroll(function () {
        if (topVal <= $(this).scrollTop() && playCount == true) {
          playCounter();
          // console.log('reached...');
        }
      });
      function playCounter() {
        playCount = false;
        obj.objGlobal.footercounter = false;
        $(".count").each(function () {
          $(this)
            .prop("Counter", 0)
            .animate(
              {
                Counter: $(this).text(),
              },
              {
                duration: 5000,
                easing: "swing",
                step: function (now) {
                  $(this).text(Math.ceil(now));
                },
              }
            );
        });
      }
    });

    // this.joinusonhome
    //   .getRealTimeStats()
    //   .pipe(
    //     tap((response) => {
    //       console.log(response);
    //       this.realTimeStats = response;
    //     }),
    //     catchError((error) => {
    //       console.error("Error in fetching realtime stats data:", error);
    //       return [];
    //     })
    //   )
    //   .subscribe();
  }
  selectedCategory(val) {
    if (this.selectedCat.indexOf(val) !== -1) {
      this.selectedCat.splice(this.selectedCat.indexOf(val), 1);
    } else {
      this.selectedCat.push(val);
    }
  }
  subscribe() {
    this.btnstatus = false;
    this.btnText = "Please wait..";
    var pagObj = this;
    let obj = {
      method: "citizen_subscribe",
      name: this.custName,
      mobile: this.custmobile,
      email: this.custemail,
      catIds: this.selectedCat.toString(),
    };
    this.joinusonhome.subscribeData(obj).subscribe((data: any) => {
      if (data.responseCode == 200) {
        Swal.fire({
          type: "success",
          text: data.message,
        }).then(function (result) {
          $("#subscribeModal").modal("hide");
        });
      } else {
        Swal.fire({
          type: "error",
          text: data.message,
        }).then(function (result) {});
      }
      pagObj.btnstatus = true;
      pagObj.btnText = "Subscribe Now";
    });
  }
}

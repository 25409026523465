import { Component, OnInit, OnDestroy, ViewEncapsulation } from "@angular/core";
import { PopularcatService } from "./popularcategories.service";
import { GlobalService } from "../global.service";
import { EventEmitterService } from "../event-emitter.service";
import { Subscription, Observable } from "rxjs";
import { StatService } from "../stats.service";
@Component({
  selector: "app-popularcategories",
  templateUrl: "./popularcategories.component.html",
  styleUrls: ["./popularcategories.component.css"],
})
export class PopularcategoriesComponent implements OnInit, OnDestroy {
  responsearr: any[] = [];
  arrcats: any[];
  arrmalls: any[];
  loadingstatus: boolean = false;
  loadingfakestatus: boolean = true;
  subcategorybody: Subscription;

  mySlideOptions = {
    items: 6,
    dots: true,
    nav: true,
    autoplay: true,
    margin: 10,
    loop: false,
    rewind: true,
    center: true,
    autoplayTimeout: 7000,
    responsive: {
      0: { items: 2 },
      480: { items: 3 },
      768: { items: 4 },
      1024: { items: 6 },
    },
    startPosition: 1,
  };

  constructor(
    private popularcatService: PopularcatService,
    private objGlobal: GlobalService,
    private eventEmitterService: EventEmitterService,
    private statService: StatService
  ) { }
  ngOnDestroy() {
    this.subcategorybody.unsubscribe();
  }
  ngOnInit() {
    this.subcategorybody =
      this.eventEmitterService.invokeCategorybodycomponentFunction.subscribe(
        (data) => {
          if (data && data.length)
            this.arrcats = data.filter(cat => cat.count_by_category > 0);
          this.loadingstatus = true;
          this.loadingfakestatus = false;
        }
      );
    // this.popularcatService.getpopularcategory("12").subscribe((data:any)=>{
    // this.arrcats=data.data;
    // this.loadingstatus=true
    // this.loadingfakestatus=false
    // })
  }
  setTrendyType(catlName, id) {
    this.objGlobal.catids = id;
    this.objGlobal.catName = catlName;
    this.postStas(id);
  }

  postStas(categoryId: number) {
    this.statService.getClientIp().subscribe((data: any) => {
      this.statService
        .postCategoryStats(data.ip, categoryId)
        .subscribe((data) => { });
    });
  }
}

import { Injectable } from "@angular/core"; //injectable represents it is a service
import { HttpClient } from "@angular/common/http"; //to do http calls we have to import httpclient from angularhttp
import { environment } from "../../../environments/environment"; //to get base url to connect the server
import { GlobalService } from "../global.service";

//service decotator
@Injectable()
export class offersservice {
  sessionUser: any;
  //base host in api

  private host = environment.API_END_POINT;
  //url
  private url: string = "";

  constructor(private http: HttpClient, private objGlobal: GlobalService) { }
  getofferData(searchtext, startindx) {
    if (this.objGlobal.filetrcat.length > 0)
      this.objGlobal.catids = this.objGlobal.filetrcat.toString();
    else this.objGlobal.catids = "0";
    var data = {
      method: "citizen_post_ads",
      city_id: this.objGlobal.cityid,
      offer: this.objGlobal.offer,
      pincode: this.objGlobal.pincode,
      category_id: this.objGlobal.catids,
      mall_id: this.objGlobal.mallid,
      srch_text: searchtext,
      start_index: startindx,
      limit: "8",
    };
    this.url = this.host;
    return this.http.post(this.url, data);
  }
}

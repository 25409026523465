import { formatDate } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BlogService } from "../blog.service";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-blog-list",
  templateUrl: "./blog-list.component.html",
  styleUrls: ["./blog-list.component.css"],
})
export class BlogListComponent implements OnInit {
  currentDate: string;
  blogs = [];

  constructor(private router: Router, private blogService: BlogService) {
    const today = new Date();
    this.currentDate = formatDate(today, "MMM d, yyyy", "en-US");
  }
  ngOnInit() {
    this.blogService
      .getBlogList()
      .pipe(
        catchError((error) => {
          console.error("An error occurred in get blog API:", error);
          return throwError(() => "Something went wrong. Please try again.");
        })
      )
      .subscribe((response) => {
        if (response.responseCode === "200") {
          this.blogs = [...response.blogs.data];
        }
      });
  }

  navigateToFullBlog(blogId: number): void {
    this.router.navigate(["blog", blogId]);
  }
}

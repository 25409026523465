import { Component, OnInit,OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from '../global.service';
import { EventEmitterService } from '../event-emitter.service';
import { DataService } from '../data.service';
import { Subscription,Observable } from 'rxjs';
@Component({
  selector: 'app-contactmaps',
  templateUrl: './contactmaps.component.html',
  styleUrls: ['./contactmaps.component.css']
})
export class ContactmapsComponent implements OnInit,OnDestroy {
  lat: number = 20.3456512;
  lng: number = 85.8030080;
  map: any;
  loaderstatus:boolean=false
  subMap: Subscription;
  latitudelongitude :any[]
  constructor(private ActivatedRoute:ActivatedRoute,private objGlobal: GlobalService
    ,private eventEmitterService: EventEmitterService) { }
  ngOnDestroy() {
    this.subMap.unsubscribe();
  }
  ngOnInit() {
    this.subMap =this.eventEmitterService.invokemapcomponentFunction.subscribe((latlong) => {
      this.latitudelongitude=latlong.split(",")
      this.lat=this.latitudelongitude[0]
      this.lng=this.latitudelongitude[1]
    }); 
  }
}

import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  AfterViewInit,
  ViewChildren,
  QueryList,
  ElementRef,
} from "@angular/core";
import { Subscription } from "rxjs";
import { EventEmitterService } from "../event-emitter.service";
import { popularrandService } from "../popularandoffers/popularandoffers.service";

@Component({
  selector: "app-video-ads",
  templateUrl: "./video-ads.component.html",
  styleUrls: ["./video-ads.component.css"],
})
export class VideoAdsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("carousel", { static: false }) carousel: any;
  @ViewChildren("video") videoElements!: QueryList<
    ElementRef<HTMLVideoElement>
  >;

  loadingstatus: boolean = false;
  myallSlideVideo: any;
  totalVideo: number = 0;
  startIndex: number = 2;
  title: string = "";
  description: string = "";
  videoBannerDetails: Record<string, string> = {
    content: "",
    link: "",
    bg_color: "",
    font_color: "",
  };
  subVideo: Subscription;
  currentVideoElement: HTMLVideoElement | null = null;
  private timeoutId: any;

  constructor(
    private popularrandService: popularrandService,
    private eventEmitterService: EventEmitterService
  ) {}

  ngOnDestroy() {
    clearTimeout(this.timeoutId);
    this.subVideo.unsubscribe();
  }

  ngOnInit() {
    this.subVideo =
      this.eventEmitterService.invokeVideocomponentFunction.subscribe(() => {
        this.getVideoData();
      });

    this.getVideoData();
  }

  ngAfterViewInit() {
    this.timeoutId = setTimeout(() => {
      this.initializeVideos();
    });
  }

  initializeVideos() {
    this.videoElements.changes.subscribe(() => {
      this.videoElements.forEach(
        (videoElement: ElementRef<HTMLVideoElement>, index: number) => {
          const video = videoElement.nativeElement;
          video.addEventListener("ended", () => {
            this.playNextVideo();
          });
          // Set the first video element as the current video element
          if (index === 0) {
            this.currentVideoElement = video;
          }
        }
      );
    });
  }

  playNextVideo() {
    const activeIndex = Array.from(
      document.getElementsByClassName("carousel-item")
    ).findIndex((item: Element) => item.classList.contains("active"));
    const nextIndex = (activeIndex + 1) % this.videoElements.length;

    // Pause current video
    if (this.currentVideoElement) {
      this.currentVideoElement.pause();
      this.currentVideoElement.currentTime = 0; // Reset video to beginning
    }

    // Set current video element to next video
    this.currentVideoElement =
      this.videoElements.toArray()[nextIndex].nativeElement;

    // Play next video
    this.currentVideoElement.play();

    // Remove "active" class from current item
    document
      .getElementsByClassName("carousel-item")
      [activeIndex].classList.remove("active");

    // Add "active" class to next item
    document
      .getElementsByClassName("carousel-item")
      [nextIndex].classList.add("active");
  }

  playPreviousVideo() {
    const activeIndex = Array.from(
      document.getElementsByClassName("carousel-item")
    ).findIndex((item: Element) => item.classList.contains("active"));
    const previousIndex =
      (activeIndex - 1 + this.videoElements.length) % this.videoElements.length;

    // Pause current video
    if (this.currentVideoElement) {
      this.currentVideoElement.pause();
      this.currentVideoElement.currentTime = 0; // Reset video to beginning
    }

    // Set current video element to previous video
    this.currentVideoElement =
      this.videoElements.toArray()[previousIndex].nativeElement;

    // Play previous video
    this.currentVideoElement.play();

    // Remove "active" class from current item
    document
      .getElementsByClassName("carousel-item")
      [activeIndex].classList.remove("active");

    // Add "active" class to previous item
    document
      .getElementsByClassName("carousel-item")
      [previousIndex].classList.add("active");
  }

  getVideoData() {
    this.popularrandService.getVideoData().subscribe((data: any) => {
      if (data.data.length > 0) {
        this.loadingstatus = true;
        this.myallSlideVideo = data.data;
        this.totalVideo = this.myallSlideVideo.length;
        this.videoBannerDetails = data.video_banner_text;
        // this.title = this.myallSlideVideo[0].title;
        // this.description = this.myallSlideVideo[0].description;
      } else {
        this.loadingstatus = false;
      }
    });
  }

  openLinkInNewTab(link: string): void {
    window.open(link, "_blank");
  }
}

import { Component, OnInit,ChangeDetectorRef, OnDestroy,ChangeDetectionStrategy, Input } from '@angular/core';
import { Options } from 'ng5-slider';
import { GlobalService } from '../global.service';
import 'rxjs/add/observable/merge';
import 'rxjs/add/operator/map';
import { EventEmitterService } from '../event-emitter.service';
import { DataService } from '../data.service';
import { Subscription,Observable } from 'rxjs';


import {map, startWith} from 'rxjs/operators';
export interface User {
  name: string;
}
@Component({
  selector: 'app-locationfilter',
  templateUrl: './locationfilter.component.html',
  styleUrls: ['./locationfilter.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationfilterComponent implements OnInit,OnDestroy {
  options: Options = {
    floor: 0,
    ceil: 250,
    showSelectionBar: true,
    getPointerColor: (value: number): string => {
        return '#FF0000';
    },
    getSelectionBarColor: (value: number): string => {
      return '#FF0000';
    }
  };
  minValue: number = this.options.floor;
  maxValue: number = this.options.ceil;
  keyword = 'city_name';
  data:any=[];
  cityname:any = '';
  searchtext = '';
  sub: Subscription;
  
  constructor(private objGlobal: GlobalService,private eventEmitterService: EventEmitterService,
    private ref: ChangeDetectorRef) { }
  ngOnDestroy() {
      this.sub.unsubscribe();
    }  
  ngOnInit() {
     var obj=this;
     this.sub =this.eventEmitterService.invokefiltercomponentFunction.subscribe((name:string) => {
        obj.data=[];
        obj.data=obj.objGlobal.citylist;
        let indx =obj.data.findIndex(item=>(item.city_name).toLowerCase() == name.toLowerCase());
        obj.cityname="zafir"
        if(indx!=-1){
        obj.objGlobal.cityName=obj.data[indx].city_name
        obj.objGlobal.cityid=obj.data[indx].city_id
        }
        obj.ref.markForCheck();
      });   
      obj.data=obj.objGlobal.citylist;
      let indx =obj.data.findIndex(item=>(item.city_name).toLowerCase() == (obj.objGlobal.cityName).toLowerCase());
      if(indx==-1){
        obj.cityname=obj.objGlobal.cityName
      }
      else{
        obj.cityname=obj.data[indx].city_name
      }
   
  }
  
  selectEvent(item) {
   
   //city_id: 58, city_name: "ANGUL"
  
   if(item.city_id==undefined){
    let indx =this.data.findIndex(item=>(item.city_name).toLowerCase() == (this.objGlobal.cityName).toLowerCase());
    if(indx!=-1){
    this.objGlobal.cityName=this.data[indx].city_name
    this.objGlobal.cityid=this.data[indx].city_id
    }
   }else{
    this.objGlobal.cityName=item.city_name
    this.objGlobal.cityid=item.city_id
    this.eventEmitterService.invokecarasoulcomponent();
    this.eventEmitterService.invokesidebannercomponent();
    this.eventEmitterService.invokepostcomponent();
   }
   
  
    // do something with selected item
  }
 
  onChangeSearch(val: string) {
   
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }
  
  onFocused(e){
  
    // do something when input is focused
  }
}

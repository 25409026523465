import { Component, OnInit,OnDestroy } from '@angular/core';
import { malloffersservice } from './mall-offers.service';
import { Subscription } from 'rxjs';
import { EventEmitterService } from '../event-emitter.service';
import { GlobalService } from '../global.service';
@Component({
  selector: 'app-mall-offers',
  templateUrl: './mall-offers.component.html',
  styleUrls: ['./mall-offers.component.css']
})
export class MallOffersComponent implements OnInit {
  screenWidth = window.innerWidth;
  totalMall:any = [];
  totalMallCount:any;
  title = 'owl-carousel';
  mySlideImages = new Array();
  mySlideText=[];
  myCarouselImages =['assets/Movies-category.jpg','assets/Movies-category.jpg','assets/Movies-category.jpg'];
  mySlideOptions={items: 6, dots: true, nav: true, autoplay: true, margin: 10,loop:true,rewind: true,autoplayTimeout:7000 };
  myCarouselOptions={items: 3, dots: true, nav: true};
  loadingstatus:boolean=false
  loadingfakestatus:boolean=true
  nomallloadingstatus:boolean=false
  sub: Subscription;
  constructor(private malloffersservice:malloffersservice,private eventEmitterService: EventEmitterService,private objGlobal: GlobalService) { }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  ngOnInit() {
    if(this.screenWidth <= 768) {
      this.totalMallCount = 4;
      this.totalMall = [1,2,3,4];
    }
    else if(this.screenWidth > 768) {
      this.totalMallCount = 6;
      this.totalMall = [1,2,3,4,5,6]
    }
    var obj=this;
    this.mySlideImages=[];
    this.sub =this.eventEmitterService.invokeMalloffercomponentFunction.subscribe(() => {
      this.malloffersservice.getmallofferData(this.totalMallCount).subscribe((data:any)=>{
        this.mySlideImages=[];
        if(data.data.length>0){
         data.data.forEach(element => {
          var serie = new Array(element.image, element.mall_name,element.mall_id, element.mall_address);
          this.mySlideImages.push(serie);
          this.loadingstatus=true
          this.loadingfakestatus=false
          this.nomallloadingstatus=false
        });
      }
      else{
        this.loadingstatus=false
        this.nomallloadingstatus=false
        this.nomallloadingstatus=true
      }
      })
    });
    this.malloffersservice.getmallofferData(this.totalMallCount).subscribe((data:any)=>{
      
      if(data.data.length>0){
       data.data.forEach(element => {
        var serie = new Array(element.image, element.mall_name,element.mall_id,element.mall_address);
        this.mySlideImages.push(serie);
        this.loadingstatus=true
        this.loadingfakestatus=false
        this.nomallloadingstatus=false
      });
    }
    else{
      this.loadingstatus=false
      this.loadingfakestatus=false
      this.nomallloadingstatus=true
    }
    })
  }
  setTrendyType(mallName,id) {
    this.objGlobal.trendyType="Mall"
    this.objGlobal.MallName=mallName
    this.objGlobal.mallid=id
  }
}

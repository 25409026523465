import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from './login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  isOtpFlow: boolean = false;
  otpSent: boolean = false;
  isLogin: boolean = true;
  isLoading: boolean = false;
  isForgotPassword: boolean = false;
  messages;
  states = [];
  cities = [];

  constructor(private formBuilder: FormBuilder, private loginService: LoginService) { }

  ngOnInit() {
    this.setPasswordForm();
    this.getState();
  }

  getState() {
    this.loginService.getAllState().subscribe((data: any) => {
      this.states = data.result;
    })
  }

  getCity(id: number) {
    this.loginService.getCity(id).subscribe((data: any) => {
      this.cities = data.result;
    })
  }

  showRegisterForm() {
    this.isLogin = false;
    this.otpSent = false;
    this.messages = "";
    this.isLoading = false;
    this.isForgotPassword = false;
    this.setRegisterForm();
  }

  showLoginForm() {
    this.isLogin = true;
    this.otpSent = false;
    this.messages = "";
    this.isLoading = false;
    this.isForgotPassword = false;
    this.setPasswordForm();
  }

  setOtpForm() {
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required]],
      otp: ["", [Validators.required]]
    });
  }

  setPasswordOtpForm() {
    this.loginForm = this.formBuilder.group({
      phone: ["", [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      otp: ["", [Validators.required]],
      newPassword: ["", [Validators.required]],
      confirmPassword: ["", [Validators.required]]
    });
  }

  setPasswordForm() {
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required]],
      password: ["", [Validators.required]]
    });
  }

  setRegisterForm() {
    this.loginForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required]],
      gender: ["1", [Validators.required]],
      phone: ["", [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      otp: ["", [Validators.required]],
      address: ["", [Validators.required]],
      state: ["", [Validators.required]],
      city: ["", [Validators.required]]
    });
  }

  loginOtp() {
    this.isOtpFlow = true;
    this.setOtpForm();
  }

  loginPassword() {
    this.isOtpFlow = false;
    this.setPasswordForm();
  }

  get formControl() {
    return this.loginForm.controls;
  }

  isValidEmail(email) {
    const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return emailPattern.test(email);
  }

  isValidPhoneNumber(phoneNumber) {
    const phonePattern = /^\d{10}$/;
    return phonePattern.test(phoneNumber);
  }

  onLogin(): void {
    this.isLoading = true;
    if (this.isOtpFlow && !this.otpSent) {
      this.loginService.getLoginOtp(this.loginForm.value.email).subscribe((response: any) => {
        this.isLoading = false;
        if (response.errors) {
          this.messages = response.errors
        } else {
          this.messages = [response ? response.message : ''];
        }
        if (response && response.data && response.data.otp) {
          this.otpSent = true;
        }
      }, error => {
        this.isLoading = false;
      })
    } else if (this.isOtpFlow && this.otpSent) {
      const bodyData = {
        mobile: this.loginForm.value.email,
        otp: this.loginForm.value.otp
      }
      this.loginService.loginWithOtp(bodyData).subscribe((response: any) => {
        this.isLoading = false;
        if (response.errors) {
          this.messages = response.errors
        } else {
          this.messages = [response ? response.message : ''];
        }
        if (response && response.data && response.data.id) {
          this.setId(response.data.id);
        }
      }, error => {
        this.isLoading = false;
      })
    } else {
      const isEmail = this.isValidEmail(this.loginForm.value.email);
      let bodyData;
      isEmail ?
        bodyData = {
          email: this.loginForm.value.email,
          otp: this.loginForm.value.otp,
          password: this.loginForm.value.password
        } : bodyData = {
          mobile: this.loginForm.value.email,
          otp: this.loginForm.value.otp,
          password: this.loginForm.value.password
        }
      this.loginService.loginWithPassword(bodyData).subscribe((response: any) => {
        this.isLoading = false;
        if (response.errors) {
          this.messages = response.errors
        } else {
          this.messages = [response ? response.message : ''];
        }
        if (response && response.data && response.data.id) {
          this.setId(response.data.id);
        }
      }, error => {
        this.isLoading = false;
      })
    }
  }

  onRegister() {
    this.isLoading = true;
    if (!this.otpSent) {
      this.loginService.getRegisterOtp(this.loginForm.value.phone).subscribe((response: any) => {
        this.isLoading = false;
        if (response.errors) {
          this.messages = response.errors
        } else {
          this.messages = [response ? response.message : ''];
        }
        if (response && response.data && response.data.otp) {
          this.otpSent = true;
        }
      }, error => {
        this.isLoading = false;
      })
    } else {
      const data = this.loginForm.value;
      const bodyData = {
        name: data.name,
        email: data.email,
        password: data.password,
        mobile: data.phone,
        otp: data.otp,
        gender: Number(data.gender),
        address: data.address,
        state: data.state,
        city: data.city
      }
      this.loginService.register(bodyData).subscribe((response: any) => {
        this.isLoading = false;
        if (response.errors) {
          this.messages = response.errors
        } else {
          this.messages = [response ? response.message : ''];
        }
        if (response && response.data && response.data.id) {
          this.setId(response.data.id);
        }
      }, error => {
        this.isLoading = false;
      })
    }
  }

  resendRegisterOtp() {
    this.isLoading = true;
    this.loginService.getRegisterOtp(this.loginForm.value.email).subscribe((response: any) => {
      this.isLoading = false;
      if (response.errors) {
        this.messages = response.errors
      } else {
        this.messages = [response ? response.message : ''];
      }
      if (response && response.data && response.data.otp) {
        this.otpSent = true;
      }
    }, error => {
      this.isLoading = false;
    })
  }

  resendLoginOtp() {
    this.isLoading = true;
    this.loginService.getLoginOtp(this.loginForm.value.email).subscribe((response: any) => {
      this.isLoading = false;
      if (response.errors) {
        this.messages = response.errors
      } else {
        this.messages = [response ? response.message : ''];
      }
      if (response && response.data && response.data.otp) {
        this.otpSent = true;
      }
    }, error => {
      this.isLoading = false;
    })
  }

  resendForgotPasswordOtp() {
    this.isLoading = true;
    this.loginService.getForgotPassswordOtp(this.loginForm.value.phone).subscribe((response: any) => {
      this.isLoading = false;
      if (response.errors) {
        this.messages = response.errors
      } else {
        this.messages = [response ? response.message : ''];
      }
      if (response && response.data && response.data.otp) {
        this.otpSent = true;
      }
    }, error => {
      this.isLoading = false;
    })
  }

  setId(id: any) {
    localStorage.setItem("tokenId", id);
    window.location.reload();
  }

  forgotPassword() {
    this.isLogin = false;
    this.otpSent = false;
    this.messages = "";
    this.isLoading = false;
    this.isForgotPassword = true;
    this.setPasswordOtpForm();
  }

  onForgotPasswordSubmit() {
    this.isLoading = true;
    if (!this.otpSent) {
      this.loginService.getForgotPassswordOtp(this.loginForm.value.phone).subscribe((response: any) => {
        this.isLoading = false;
        if (response.errors) {
          this.messages = response.errors
        } else {
          this.messages = [response ? response.message : ''];
        }
        if (response && response.data && response.data.otp) {
          this.otpSent = true;
        }
      }, error => {
        this.isLoading = false;
      })
    } else {
      const data = this.loginForm.value;
      const bodyData = {
        new_password: data.newPassword,
        mobile: data.phone,
        otp: data.otp
      }
      this.loginService.forgotPassword(bodyData).subscribe((response: any) => {
        this.isLoading = false;
        if (response.errors) {
          this.messages = response.errors
        } else {
          this.messages = [response ? response.message : ''];
        }
        if (response && response.data && response.data.id) {
          this.setId(response.data.id);
        }
      }, error => {
        this.isLoading = false;
      })
    }
  }
}

import { Injectable } from "@angular/core"; //injectable represents it is a service
import { HttpClient } from "@angular/common/http"; //to do http calls we have to import httpclient from angularhttp
import { environment } from "../../../environments/environment"; //to get base url to connect the server
import { GlobalService } from "../global.service";

//service decotator
@Injectable()
export class popularrandService {
  sessionUser: any;
  //base host in api
  private host = environment.API_END_POINT;
  //url
  private url: string = "";

  constructor(private http: HttpClient, private GlobalService: GlobalService) {}
  getVideoData() {
    var data = {
      method: "video_banner",
      city_id: this.GlobalService.cityid,
      cat_id: this.GlobalService.catids,
      offer: this.GlobalService.offer,
      pincode: this.GlobalService.pincode,
      mall_id: this.GlobalService.mallid,
    };
    this.url = this.host;
    return this.http.post(this.url, data);
  }
}

import { Component, OnInit } from "@angular/core";
import { offerscontent } from "./offerscontent.service";
import { ActivatedRoute } from "@angular/router";
import { GlobalService } from "../global.service";
// import { ShareButton } from '@ngx-share/button';
import $ from "jquery";

@Component({
  selector: "app-offerscontent",
  templateUrl: "./offerscontent.component.html",
  styleUrls: ["./offerscontent.component.css"],
})
export class OfferscontentComponent implements OnInit {
  arrAdvContent: any = [];
  image: any = "";
  title: any = "";
  address: any = "";
  shop_name: any = "";
  description: any = "";
  expire_date: any = "";
  rating: any = "";
  category_name: any = "";
  sub_category_name: any = "";
  shop_id: any = "";
  loading: boolean = false;
  ratingcount: any = "";
  ratingcolor: any = "";
  reviewcount: any = "";
  copyUrlTooltip: string = "Copy URL";
  showTooltip: boolean = false;
  bookmark: boolean = false;
  postId: any;
  userId
  
  constructor(
    private offerscontent: offerscontent,
    private ActivatedRoute: ActivatedRoute,
    private objGlobal: GlobalService
  ) { }

  ngOnInit() {
    this.userId = localStorage.getItem("tokenId");

    this.ActivatedRoute.params.subscribe((params) => {
      this.postId = params["post_id"];
      this.offerscontent
        .getoffercontentData(params["post_id"])
        .subscribe((data: any) => {
          this.bookmark = data.data[0].has_bookmarked;
          this.image = data.data[0].web_image;
          this.title = data.data[0].title;
          this.address = data.data[0].address;
          this.shop_name = data.data[0].shop_name;
          this.description = data.data[0].description;
          this.expire_date = data.data[0].expire_date;
          this.rating = data.data[0].rating;
          this.category_name = data.data[0].category_name;
          this.sub_category_name = data.data[0].subcat_name;
          this.shop_id = data.data[0].shop_id;
          this.arrAdvContent = data.data[0].all_comments;
          this.objGlobal.lat = data.data[0].lat;
          this.objGlobal.long = data.data[0].long;
          this.objGlobal.shopId = data.data[0].shop_id;
          this.ratingcount = data.data[0].total_rating;
          this.reviewcount = data.data[0].total_reviews;
          this.ratingcolor = data.data[0].rating_color_web;
          this.loading = true;
        });
    });

    $(function () {
      // const fb = document.getElementById('fb');
      // fb.addEventListener('click', shareonFacebook);

      // var url = 'https://biggdiscount.com/#/offers/25';
      var url = "" + window.location.href;
      $("#facebook_share").click(function () {
        const navUrl = "https://www.facebook.com/sharer/sharer.php?u=" + url;
        window.open(navUrl, "_blank");
      });
      $("#twitter_share").click(function () {
        const navUrl = "https://twitter.com/intent/tweet?text=" + url;
        window.open(navUrl, "_blank");
      });
    });
  }
  onRate(starevent) { }

  copyURL() {
    // Get the current URL
    const currentURL = window.location.href;

    // Use the Clipboard API to copy the URL to the clipboard
    navigator.clipboard
      .writeText(currentURL)
      .then(() => {
        // Update the tooltip text
        this.copyUrlTooltip = "Copied!";
        this.showTooltip = true;

        // Hide the tooltip after a certain time (e.g., 2 seconds)
        setTimeout(() => {
          this.copyUrlTooltip = "Copy URL";
          this.showTooltip = false;
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy URL:", error);
      });
  }

  bookmarkOffer() {
    this.bookmark = !this.bookmark;
    this.offerscontent.bookmarkOffer(this.postId, this.bookmark).subscribe((response: any) => {})
  }
}

import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {

  invokefiltercomponentFunction = new EventEmitter();
  invokecarasoulcomponentFunction = new EventEmitter();
  invokesidebannercomponentFunction = new EventEmitter();
  invokepostcomponentFunction = new EventEmitter();
  invokeselectcitycomponentFunction = new EventEmitter();
  invokeMalloffercomponentFunction = new EventEmitter();
  invokeMallofferlistcomponentFunction = new EventEmitter();
  invokeTrendyoffercomponentFunction = new EventEmitter();
  invokeTrendyofferlistcomponentFunction = new EventEmitter();
  invokeCategorybodycomponentFunction = new EventEmitter();
  invokeCategoryfootercomponentFunction = new EventEmitter();
  invokemapcomponentFunction = new EventEmitter();
  invokeVideocomponentFunction = new EventEmitter();
  invokeChatBotComponentFunction = new EventEmitter();
  invokeChatBotAction = new EventEmitter();

  subsVar: Subscription;

  constructor() { }
  //city filter event
  invokefiltercomponent(name: string) {
    this.invokefiltercomponentFunction.emit(name);
  }
  //carasoul event
  invokecarasoulcomponent() {
    this.invokecarasoulcomponentFunction.emit();
  }
  invokesidebannercomponent() {
    this.invokesidebannercomponentFunction.emit();
  }
  invokepostcomponent() {
    this.invokepostcomponentFunction.emit();
  }
  invokeselectcitycomponent() {
    this.invokeselectcitycomponentFunction.emit();
  }
  invokeMalloffercomponent() {
    this.invokeMalloffercomponentFunction.emit();
  }
  invokeMallofferlistFunction() {
    this.invokeMallofferlistcomponentFunction.emit();
  }
  invokeTrendyofferFunction() {
    this.invokeTrendyoffercomponentFunction.emit();
  }
  invokeTrendyofferlistFunction() {
    this.invokeTrendyofferlistcomponentFunction.emit();
  }
  invokeCategorybodyFunction(category: any) {
    this.invokeCategorybodycomponentFunction.emit(category);
  }
  invokeCategoryfooterFunction(category: any) {
    this.invokeCategoryfootercomponentFunction.emit(category);
  }
  invokemapFunction(latlong: any) {
    this.invokemapcomponentFunction.emit(latlong);
  }
  invokeVideoFunction() {
    this.invokeVideocomponentFunction.emit();
  }

  invokeChatBotFunction() {
    this.invokeChatBotComponentFunction.emit();
  }

  invokeChatBotActionFunction(type:number) {
    this.invokeChatBotAction.emit(type);
  }

}    

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-franchisebody',
  templateUrl: './franchisebody.component.html',
  styleUrls: ['./franchisebody.component.css']
})
export class FranchisebodyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit,OnDestroy  } from '@angular/core';
import { mallofferslistpageService } from './malloffers-list-page.service';
import { Subscription } from 'rxjs';
import { EventEmitterService } from '../event-emitter.service';
import { GlobalService } from '../global.service';
@Component({
  selector: 'app-malloffers-list-page',
  templateUrl: './malloffers-list-page.component.html',
  styleUrls: ['./malloffers-list-page.component.css']
})
export class MalloffersListPageComponent implements OnInit {
  mallData = new Array();
  startindex:any=0
  scrollsize:any=2
  scrollStatus:any="Y"
  loadingstatus:boolean=false
  noloadingstatus:boolean=false
  submalloffer: Subscription;
  constructor(private mallofferslistpageService:mallofferslistpageService,
    private eventEmitterService: EventEmitterService,private objGlobal: GlobalService) { }
  ngOnDestroy() {
    this.submalloffer.unsubscribe();
  }
  ngOnInit() {
    var obj=this;
    obj.mallData=[]
    obj.scrollStatus="Y"
    this.startindex=0;
    this.submalloffer =this.eventEmitterService.invokeMallofferlistcomponentFunction.subscribe(() => {
      obj.mallData=[]
      this.startindex=0;
      obj.scrollStatus="Y"
      obj.mallofferslistpageService.getAllMalllData(this.startindex).subscribe((data:any)=>{
        if(data.data.length>0){
          data.data.forEach(element => {
            var serie = new Array(element.image, element.mall_name,element.mall_address,element.mall_id,element.total_posts);
            obj.mallData.push(serie);
          });
          obj.noloadingstatus=false;
        }
        else{
          obj.scrollStatus="N"
          obj.noloadingstatus=true;
        }
      })

    });
    this.mallofferslistpageService.getAllMalllData(this.startindex).subscribe((data:any)=>{
      if(data.data.length>0){
        data.data.forEach(element => {
          var serie = new Array(element.image, element.mall_name,element.mall_address,element.mall_id,element.total_posts);
          obj.mallData.push(serie);
        });
        this.noloadingstatus=false;
      }
      else{
        obj.scrollStatus="N"
        this.noloadingstatus=true;
      }
    })
  }
  onScroll() { 
     this.loadingstatus=true;
     var obj=this;
     this.startindex=(this.startindex)+6; 
     if(this.scrollStatus=="Y"){
      this.mallofferslistpageService.getAllMalllData(this.startindex).subscribe((data:any)=>{
       if(data.data.length>0){
         data.data.forEach(element => {
          var serie = new Array(element.image, element.mall_name,element.mall_address,element.mall_id,element.total_posts);
           obj.mallData.push(serie);          
         });
       }
       else{
         this.scrollStatus="N"
       }
       this.loadingstatus=false;

       })
       
     }
     else{
       this.loadingstatus=false;
     }
 
   }
   setTrendyType(mallName,id) {
    this.objGlobal.trendyType="Mall"
    this.objGlobal.MallName=mallName
    this.objGlobal.mallid=id
  }
}

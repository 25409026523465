import { Component, OnInit } from '@angular/core';
import { ProfileService } from './profile.service';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import { MatDialog } from '@angular/material';
import { EditProfileComponent } from '../edit-profile/edit-profile.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  userData

  constructor(private profileService: ProfileService, private dialog: MatDialog) { }

  ngOnInit() {
    this.profileService.getProfile().subscribe((data: any) => {
      if (data && data.data) {
        this.userData = data.data;
      }
    })
  }

  openResetPasswordDialog(): void {
    this.dialog.open(ResetPasswordComponent, { width: "400px" });
  }

  openEditProfiledDialog(): void {
    this.dialog.open(EditProfileComponent, {
      data: this.userData, width: "400px"
    });
  }
}

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { HomeComponent } from "./components/home/home.component";
import { AboutComponent } from "./components/about/about.component";
import { ContactComponent } from "./components/contact/contact.component";
import { FranchiseComponent } from "./components/franchise/franchise.component";
import { RegisterComponent } from "./components/register/register.component";
import { ReviewComponent } from "./components/review/review.component";
import { ShopdetailsComponent } from "./components/shopdetails/shopdetails.component";
import { MallDetailsWihtOutRelatedComponent } from "./components/mall-details-wiht-out-related/mall-details-wiht-out-related.component";
import { OffersComponent } from "./components/offers/offers.component";
import { TrendyOffersComponent } from "./components/trendy-offers/trendy-offers.component";
import { MalloffersListComponent } from "./components/malloffers-list/malloffers-list.component";
import { TrendyoffersDetailsListComponent } from "./components/trendyoffers-details-list/trendyoffers-details-list.component";
import { ErrorComponent } from "./components/error/error.component";
import { CareerComponent } from "./components/career/career.component";
import { Resolver } from "./components/resolver";
import { SelectLocationComponent } from "./components/select-location/select-location.component";
import { PrivacyPolicyComponent } from "./components/privacy-policy/privacy-policy.component";
import { TermsOfUseComponent } from "./components/terms-of-use/terms-of-use.component";
import { DisclaimerComponent } from "./components/disclaimer/disclaimer.component";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { BlogComponent } from "./components/blog/blog.component";
import { FullBlogComponent } from "./components/blog/full-blog/full-blog.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { AuthGuard } from "./auth/auth.guard";

const appRoutes: Routes = [
  { path: "", component: HomeComponent },
  // { path: '', component: HomeComponent ,resolve: { country: Resolver }},
  { path: "profile", component: ProfileComponent,canActivate:[AuthGuard] },
  { path: "about", component: AboutComponent },
  { path: "contact", component: ContactComponent },
  { path: "franchaise", component: FranchiseComponent },
  { path: "registerasshop", component: RegisterComponent },
  { path: "review/:post_id", component: ReviewComponent },
  { path: "shopdetails/:shop_id", component: ShopdetailsComponent },
  { path: "offers/:post_id", component: OffersComponent },
  { path: "malldetails/:id", component: MallDetailsWihtOutRelatedComponent },
  { path: "trendyoffers/:catid", component: TrendyOffersComponent },
  { path: "mallofferslist", component: MalloffersListComponent },
  {
    path: "trendyoffersdetails/:searchtext",
    component: TrendyoffersDetailsListComponent,
  },
  { path: "trendyoffersdetails", component: TrendyoffersDetailsListComponent },
  { path: "error", component: ErrorComponent },
  { path: "career", component: CareerComponent },
  { path: "select-location", component: SelectLocationComponent },
  { path: "privacy-policy", component: PrivacyPolicyComponent },
  { path: "terms-of-use", component: TermsOfUseComponent },
  { path: "disclaimer", component: DisclaimerComponent },
  { path: "blog", component: BlogComponent },
  { path: "blog/:id", component: FullBlogComponent },
  { path: "**", component: PageNotFoundComponent },
];

// @NgModule({
//   declarations: [],
//   imports: [
//     CommonModule
//   ]
// })
// export class AppRoutingModule { }
@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
// export const routing = RouterModule.forRoot(appRoutes,{useHash:true})

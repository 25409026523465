import { Component, OnInit } from '@angular/core';
import { franchisecontentService } from './franchisecontent.service';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-franchisecontent',
  templateUrl: './franchisecontent.component.html',
  styleUrls: ['./franchisecontent.component.css']
})
export class FranchisecontentComponent implements OnInit {
  country = new Array();
  state :any;
  city :any;
  allData:any;
  cuntryId:"";
  cityId:"";
  stateId:"";
  custName:"";
  designation:"";
  company:"";
  address:"";
  mobile:"";
  email:"";
  website:"";
  btnstatus:boolean=true
  btnText="Submit"
  constructor(public franchisecontentService: franchisecontentService) { 
    
  }

  ngOnInit() {
    var obj=this;
    this.franchisecontentService.getDemographyData1().subscribe((data:any)=>{
      this.allData=data.data;
      data.data.forEach(element => {
        var serie = new Array(element.name, element.id);
        obj.country.push(serie);
      });
    });
  }
  changeCountry(cntId) {
    this.state=[];
    this.cuntryId=cntId;
    this.allData.forEach(element => {
      if(element.id==cntId){
        this.state=element.state
        
      }
    });
  }
  changeState(stId) {
    this.city=[];
    this.stateId=stId;
    this.state.forEach(element => {
      if(element.id==stId){
        this.city=element.city
      }
    });
  }
  changeCity(stId) {
    this.cityId=stId;
  }
  register() {
    this.btnstatus=false
    this.btnText="Please wait.."
    var objthis=this;
    let obj={
      "method":"citizen_register_as_franchise",
      "company_name":this.company,
      "website":this.website,
      "address":this.address,
      "country":this.cuntryId,
      "state":this.stateId,
      "city":this.cityId,
      "contact_person":this.custName,
      "designation":this.designation,
      "mobile":this.mobile,
      "email":this.email
    }
    this.franchisecontentService.franchiseRegister(obj).subscribe((data:any)=>{
    
      if(data.responseCode==200)
      {
       Swal.fire({
         type: 'success',
         text:  data.message
       }).then(function(result){
        
       })
      }
     else{
       Swal.fire({
         type: 'error',
         text:  data.message
       }).then(function(result){
         
       })
     }
     objthis.btnstatus=true
     objthis.btnText="Submit"
     this.cuntryId="";
     this.cityId="";
     this.stateId="";
     this.custName="";
     this.designation="";
     this.company="";
     this.address="";
     this.mobile="";
     this.email="";
     this.website="";
     this.state=[];
     this.city=[];
     this.country= new Array();
     this.allData.forEach(element => {
       var serie = new Array(element.name, element.id);
       objthis.country.push(serie);
     });
    })
   

  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private host = environment.API_END_POINT;
  private url: string = '';

  constructor(private http: HttpClient) { }

  getProfile() {
    const user_id = localStorage.getItem("tokenId");
    var data = {
      method: "get_logged_user_data",
      user_id

    };
    this.url = this.host;
    return this.http.post(this.url, data);
  }
}
import { Component, Input, OnInit } from '@angular/core';
import { EventEmitterService } from '../event-emitter.service';

@Component({
  selector: 'app-dynamic-content',
  templateUrl: './dynamic-content.component.html',
  styleUrls: ['./dynamic-content.component.css']
})
export class DynamicContentComponent implements OnInit {
  @Input() content: any;
  buttonDisabled: boolean = false;
  selectedType: number;

  constructor(
    private eventEmitterService: EventEmitterService
  ) { }

  ngOnInit() {
  }

  setType(type: number) {
    if (!this.buttonDisabled) {
      this.buttonDisabled = true;
      this.selectedType = type;
      // this.eventEmitterService.invokeChatBotActionFunction(type);
    }
  }
}

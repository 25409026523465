import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BlogService, blogCommentInput } from "../blog.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-comment",
  templateUrl: "./comment.component.html",
  styleUrls: ["./comment.component.css"],
})
export class CommentComponent {
  commentForm: FormGroup;
  blogId: number;
  @Input() parentComment: any;
  @Output() updateCommentList = new EventEmitter<any>();


  constructor(
    private fb: FormBuilder,
    private blogService: BlogService,
    private route: ActivatedRoute
  ) {
    this.commentForm = this.fb.group({
      commentText: ["", Validators.required],
      commenterName: ["", Validators.required],
      // commenterEmail: ["", [Validators.required, Validators.email]],
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.blogId = +params["id"];
    });
  }

  postComment() {
    if (this.commentForm.valid) {
      const payload: blogCommentInput = {
        blog_id: this.blogId,
        name: this.commentForm.get("commenterName").value,
        comment: this.commentForm.get("commentText").value,
      };

      // Check if it's a reply or an original comment
      if (!!this.parentComment) {
        this.blogService
          .postBlogReplyComment({
            ...payload,
            comment_id: this.parentComment.id,
            reply: payload.comment,
          })
          .subscribe(
            (response) => {
              this.updateCommentList.emit();
              this.commentForm.reset();
              for (let control in this.commentForm.controls) {
                this.commentForm.controls[control].setErrors(null);
              }
            },
            (error) => {
              console.error("Error posting comment:", error);
            }
          );
        // It's a reply, add the parentCommentId to the payload
      } else {
        this.blogService.postBlogComment(payload).subscribe(
          (response) => {
            this.commentForm.reset();
            for (let control in this.commentForm.controls) {
              this.commentForm.controls[control].setErrors(null);
            }
            this.updateCommentList.emit();
          },
          (error) => {
            console.error("Error posting comment:", error);
          }
        );
      }
    }
  }
}

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DatePipe } from "@angular/common";

import { BlogService } from "../blog.service";
import { DomSanitizer } from "@angular/platform-browser";

export interface Comment {
  name: string; // Name of the commenter
  text: string; // Comment text
  date: Date; // Date and time when the comment was posted
  replies: Comment[]; // An array to store replies to this comment
}

@Component({
  selector: "app-full-blog",
  templateUrl: "./full-blog.component.html",
  styleUrls: ["./full-blog.component.css"],
})
export class FullBlogComponent implements OnInit {
  blogId: number;
  blog: any = {};
  comments: Comment[] = []; // Create an array to store comments and replies
  replyStates: { [commentId: number]: boolean } = {};
  parentCommentId: number | null = null;

  constructor(
    private route: ActivatedRoute,
    private blogService: BlogService,
    private sanitized: DomSanitizer
  ) {}

  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.blogId = +params["id"]; // Convert the parameter to a number

      // Call a method to fetch blog details using the blogId
      this.fetchBlogDetails();
    });
  }

  fetchBlogDetails() {
    // Use the BlogService to fetch the blog details by blogId
    this.blogService.getBlogById(this.blogId).subscribe((blog) => {
      // Handle the received blog data
      if (blog.responseCode === "200") {
        this.blog = blog.data;
        this.blog.comments = this.blog.comments.reverse();        
      }
    });
  }
  // ... existing code

  addComment(commentText: string, name: string) {
    // Create a new comment object
    const newComment: Comment = {
      name: name,
      text: commentText,
      replies: [],
      date: new Date(),
    };

    // Add the new comment to the comments array
    this.comments.push(newComment);

    // Clear the input fields
    // You can add logic here to send the comment to your server/API if needed
  }

  toggleReply(commentId: number) {
    // Toggle the reply section for a specific comment
    this.replyStates[commentId] = !this.replyStates[commentId];
    this.parentCommentId = commentId;
  }

  updateCommentList(){    
    this.fetchBlogDetails();    
  }
}

import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { masterService } from './master.service';

@Injectable()
export class Resolver implements Resolve<Observable<string>> {
  constructor(private api: masterService) { }

  resolve() {
    return this.api.getDemographyData();
  }
}
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private host = environment.API_END_POINT;
  private url: string = '';

  rating: number = 0;
  constructor(private http: HttpClient) { }

  getLoginOtp(mobile: string) {
    var data = {
      method: "login_otp_send",
      mobile: mobile
    };
    this.url = this.host;
    return this.http.post(this.url, data);
  }

  getRegisterOtp(mobile: string) {
    var data = {
      method: "register_otp_send",
      mobile: mobile
    };
    this.url = this.host;
    return this.http.post(this.url, data);
  }

  register(body: any) {
    var data = {
      method: "create_user",
      ...body

    };
    this.url = this.host;
    return this.http.post(this.url, data);
  }

  loginWithOtp(body: any) {
    var data = {
      method: "login_with_otp",
      ...body

    };
    this.url = this.host;
    return this.http.post(this.url, data);
  }

  loginWithPassword(body: any) {
    var data = {
      method: "login_user",
      ...body

    };
    this.url = this.host;
    return this.http.post(this.url, data);
  }

  getAllState() {
    var data = {
      method: "get_all_state"
    };
    this.url = this.host;
    return this.http.post(this.url, data);
  }

  getCity(stateId: number) {
    var data = {
      method: "get_city",
      stateId: stateId
    };
    this.url = this.host;
    return this.http.post(this.url, data);
  }

  resetPassword(body: any) {
    var data = {
      method: "change_password_user",
      ...body

    };
    this.url = this.host;
    return this.http.post(this.url, data);
  }

  editProfile(body: any) {
    this.url = this.host;
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'multipart/form-data');
    return this.http.post(this.url, body, { headers });
  }

  getForgotPassswordOtp(mobile: string) {
    var data = {
      method: "forgot_password_otp_send",
      mobile: mobile
    };
    this.url = this.host;
    return this.http.post(this.url, data);
  }

  forgotPassword(body: any) {
    var data = {
      method: "forgot_password_user",
      ...body

    };
    this.url = this.host;
    return this.http.post(this.url, data);
  }
}
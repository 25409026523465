import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from '../login/login.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  isLoading: boolean = false;
  messages

  constructor(private formBuilder: FormBuilder, private loginService: LoginService) { }

  ngOnInit() {
    this.setForm();
  }

  setForm() {
    this.resetPasswordForm = this.formBuilder.group({
      oldPassword: ["", [Validators.required]],
      newPassword: ["", [Validators.required]],
      confirmPassword: ["", [Validators.required]]
    });
  }

  get formControl() {
    return this.resetPasswordForm.controls;
  }

  onSubmit() {
    const bodyData = {
      old_password: this.resetPasswordForm.value.oldPassword,
      new_password: this.resetPasswordForm.value.newPassword,
      user_id: localStorage.getItem("tokenId")
    }
    this.loginService.resetPassword(bodyData).subscribe((response: any) => {
      this.isLoading = false;
      if (response.errors) {
        this.messages = response.errors
      } else {
        this.messages = [response ? response.message : ''];
        window.location.reload();
      }
    }, error => {
      this.isLoading = false;
    })
  }
}

import { Injectable } from '@angular/core';//injectable represents it is a service
import { HttpClient } from '@angular/common/http';//to do http calls we have to import httpclient from angularhttp
import { environment } from '../../../environments/environment';//to get base url to connect the server


//service decotator
@Injectable()
export class PopularcatService {
  sessionUser: any;
  //base host in api
  private host = environment.API_END_POINT;
  private host1 = environment.CATEGORY_API_END_POINT;
  //url
  private url: string = '';

  constructor(private http: HttpClient) {

  }
  getpopularcategory(lmtcount, cityId) {
    var data = {
      "method": "citizen_all_category",
      "limit": lmtcount,
      city_id: cityId
    };
    this.url = this.host;
    return this.http.post(this.url, data);
  }
  getpopularcategorybycaching() {
    this.url = this.host1;
    return this.http.get(this.url);
  }
}


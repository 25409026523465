import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-listing',
  templateUrl: './about-listing.component.html',
  styleUrls: ['./about-listing.component.css']
})
export class AboutListingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

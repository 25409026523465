import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-registerlisting',
  templateUrl: './registerlisting.component.html',
  styleUrls: ['./registerlisting.component.css']
})
export class RegisterlistingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';
import { careerService } from './career.service';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.css']
})
export class CareerComponent implements OnInit {
  country = new Array();
  state :any;
  city :any;
  allData:any;
  cuntryId:"";
  cityId:"";
  stateId:"";
  custName:"";
  qualification:"";
  address:"";
  mobile:"";
  email:"";
  constructor(public careerService: careerService) { }

  ngOnInit() {
    var obj=this;
    this.careerService.getDemographyData().subscribe((data:any)=>{
      this.allData=data.data;
      data.data.forEach(element => {
        var serie = new Array(element.name, element.id);
        obj.country.push(serie);
      });
    });
  }
  changeCountry(cntId) {
    this.state=[];
    this.cuntryId=cntId;
    this.allData.forEach(element => {
      if(element.id==cntId){
        this.state=element.state
        
      }
    });
  }
  changeState(stId) {
    this.city=[];
    this.stateId=stId;
    this.state.forEach(element => {
      if(element.id==stId){
        this.city=element.city
      }
    });
  }
  changeCity(stId) {
    this.cityId=stId;
  }
  register() {
    var objthis=this;
    let obj={
      "method":"citizen_register_as_franchise",
      "address":this.address,
      "country":this.cuntryId,
      "state":this.stateId,
      "city":this.cityId,
      "contact_person":this.custName,
      "designation":this.qualification,
      "mobile":this.mobile,
      "email":this.email
    }
    this.careerService.careerRegister(obj).subscribe((data:any)=>{
    
      if(data.responseCode==200)
      {
       Swal.fire({
         type: 'success',
         text:  data.message
       }).then(function(result){
        
       })
      }
     else{
       Swal.fire({
         type: 'error',
         text:  data.message
       }).then(function(result){
         
       })
     }
     this.cuntryId="";
     this.cityId="";
     this.stateId="";
     this.custName="";
     this.qualification="";
     this.address="";
     this.mobile="";
     this.email="";
     this.state=[];
     this.city=[];
     this.country= new Array();
     this.allData.forEach(element => {
       var serie = new Array(element.name, element.id);
       objthis.country.push(serie);
     });
    })
   

  }
}

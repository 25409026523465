import { Injectable } from '@angular/core';//injectable represents it is a service
import { HttpClient } from '@angular/common/http';//to do http calls we have to import httpclient from angularhttp
import { environment } from '../../../environments/environment';//to get base url to connect the server
import { StarRatingComponent } from 'ng-starrating';

//service decotator
@Injectable()
export class offerscontent {
  sessionUser: any;
  //base host in api
  private host = environment.API_END_POINT;
  //url
  private url: string = '';

  rating: number = 0;
  constructor(private http: HttpClient) { }

  ngOnInit() {
  }
  // onRate($event:{oldValue:number, newValue:number, starRating:StarRatingComponent}) {
  //   // alert(`Old Value:${$event.oldValue}, 
  //   //   New Value: ${$event.newValue}, 
  //   //   Checked Color: ${$event.starRating.checkedcolor}, 
  //   //   Unchecked Color: ${$event.starRating.uncheckedcolor}`);
  // }
  getoffercontentData(postId) {
    const user_id = localStorage.getItem("tokenId");

    var data: any = {
      "method": "citizen_post_details",
      "post_id": postId
    };
    user_id ? data.user_id = user_id : ''
    this.url = this.host;
    return this.http.post(this.url, data);
  }

  bookmarkOffer(postId, bookmark) {
    const user_id = localStorage.getItem("tokenId");

    var data = {
      method: "store_bookmark",
      module_type: 3,
      module_id: postId,
      bookmark,
      user_id
    };
    this.url = this.host;
    return this.http.post(this.url, data);
  }
}


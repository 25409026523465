import { Injectable } from '@angular/core';//injectable represents it is a service
import { HttpClient } from '@angular/common/http';//to do http calls we have to import httpclient from angularhttp
import { environment } from '../../../environments/environment';//to get base url to connect the server


//service decotator
@Injectable()
export class shopdetailscontentService {
  sessionUser: any;
  //base host in api
  private host = environment.API_END_POINT;
  //url
  private url: string = '';

  constructor(private http: HttpClient) {

  }
  getShopDetailsData(shop_id) {
    const user_id = localStorage.getItem("tokenId");
    var data:any = {
      "method": "citizen_view_shop_posts",
      "shop_id": shop_id
    };
    user_id ? data.user_id = user_id : ''
    this.url = this.host;
    return this.http.post(this.url, data);
  }

  follow(shop_id: any, follow: any) {
    const user_id = localStorage.getItem("tokenId");
    var data = {
      method: "store_follow_shop",
      shop_id,
      user_id,
      follow: follow ? 1 : 0
    };
    this.url = this.host;
    return this.http.post(this.url, data);
  }
}


import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mall-details-wiht-out-relatedbody',
  templateUrl: './mall-details-wiht-out-relatedbody.component.html',
  styleUrls: ['./mall-details-wiht-out-relatedbody.component.css']
})
export class MallDetailsWihtOutRelatedbodyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

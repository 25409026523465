import { ComponentFactoryResolver, Injectable, ViewContainerRef } from '@angular/core';
import { DynamicContentComponent } from './dynamic-content/dynamic-content.component';

@Injectable({
  providedIn: 'root'
})
export class DynamicContentService {

  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  createDynamicComponent(viewContainerRef: ViewContainerRef, content: any) {
    const factory = this.componentFactoryResolver.resolveComponentFactory(DynamicContentComponent);
    const componentRef = viewContainerRef.createComponent(factory);
    componentRef.instance.content = content;
  }
}

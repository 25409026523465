import { Injectable } from "@angular/core"; //injectable represents it is a service
import { HttpClient } from "@angular/common/http"; //to do http calls we have to import httpclient from angularhttp
import { environment } from "../../../environments/environment"; //to get base url to connect the server
import { Observable } from "rxjs";

//service decotator
@Injectable()
export class joinusonhome {
  sessionUser: any;
  //base host in api
  private host = environment.API_END_POINT;
  //url
  private url: string = "";

  constructor(private http: HttpClient) {}
  subscribeData(data) {
    this.url = this.host;
    return this.http.post(this.url, data);
  }

  getRealTimeStats(): Observable<any> {
    return this.http.post(
      `${environment.BASE_API_END_POINT}/realtimestats`,
      null
    );
  }
}
